import React from "react"

import { useGlobalState } from "@app/state"
import { Login, Navigation, BannerImage, Footer } from "@app/components"

export const MainLayout = ({ children, className = "", page, loading, plain = false }) => {
	const [showLogin] = useGlobalState("showLogin")
	return (
		<div
			className={`flex flex-col min-h-screen justify-between ${showLogin ? "fixed w-full" : ""}`}
		>
			{showLogin ? <Login /> : null}
			{!plain && <Navigation />}
			{page ? <BannerImage page={page} /> : null}
			<main className={`mb-auto ${className}`}>
				{loading ? (
					<div className="w-full flex items-center justify-center p-10">
						<div className="loading" />
					</div>
				) : (
					children
				)}
			</main>
			{!plain && <Footer />}
		</div>
	)
}
