import React from "react"
import _ from "lodash"
import { parseJSON, format } from "date-fns"
import { PrismicRichText } from "@prismicio/react"
import { useParams } from "react-router-dom"

import { MainLayout } from "@app/layouts"
import { useHTTPStatus } from "@app/contexts"
import { client } from "@app/prismic"

export const BlogPostPage = () => {
	const [post, setPost] = React.useState()
	const loading = _.isEmpty(post)

	let { id } = useParams()
	const { setHTTPStatus } = useHTTPStatus()

	React.useEffect(() => {
		let mounted = true
		const load = async () => {
			const resp = await client.getByID(id)
			if (!mounted) {
				return
			}
			if (resp) {
				setPost(resp)
			} else {
				setHTTPStatus(404)
			}
		}
		load()
		return () => {
			mounted = false
		}
	}, [])

	if (loading) {
		return null
	}

	const title = _.get(post, "data.title[0].text", "")
	const content = _.get(post, "data.content")
	const date = parseJSON(_.get(post, "first_publication_date"))

	return (
		<MainLayout page={"blog"}>
			<div className="mx-auto max-w-md px-4 py-6">
				<div className="text-gray-900 text-4xl">{title}</div>
				<div className="text-gray-600 text-sm mb-4">{format(date, "MMM dd, yyyy")}</div>
				<div className="prismic">
					<PrismicRichText field={content} />
				</div>
			</div>
		</MainLayout>
	)
}
