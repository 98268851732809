import React from "react"
import _ from "lodash"

import { Icon, Link } from "@app/components"
import { useSession } from "@app/contexts"
import { formatNumber } from "@app/util"

export const SampleRequestItems = ({ controls = true }) => {
	const { basket, adjustSampleBottleQuantity } = useSession()
	const { totalQuantity } = _.reduce(
		basket.items,
		(acc, item) => {
			acc.totalQuantity += item.sampleBottleQuantity
			return acc
		},
		{ totalQuantity: 0 },
	)

	return (
		<table className="text-lg w-full">
			<thead>
				<tr>
					<th className="border-b border-t border-gray-400 font-normal pt-2 pb-1 hidden md:table-cell"></th>
					<th className="text-gray-600 text-left border-b border-t border-gray-400 font-normal pt-2 pb-1">
						Name
					</th>
					<th className="text-gray-600 text-right border-b border-t border-gray-400 font-normal pt-2 pb-1">
						Bottles
					</th>
					<th className="border-b border-t border-gray-400 font-normal pt-2 pb-1 hidden md:table-cell"></th>
				</tr>
			</thead>
			<tbody>
				{_.map(basket.items, (item, i) => {
					const quantityRef = React.createRef()
					const { product, sampleBottleQuantity } = item
					return (
						<tr key={`${product.id}${i}`}>
							<td className="hidden md:table-cell">
								<Link to={`/shop/wines/${product.id}/${product.nameSlug}`}>
									<img
										className="object-none object-center object-scale-down h-10 my-2 flex-shrink-0 mx-auto bg-white w-8"
										src={_.get(product, "thumbnails[0]", "/assets/img/bottle-green.jpg")}
										alt="Bottle image"
									/>
								</Link>
							</td>
							<td className="py-1">
								<Link to={`/shop/wines/${product.id}/${product.nameSlug}`}>
									{`${product.vintage} ${product.name}`}
								</Link>
							</td>
							<td className="py-1 text-right">
								{controls ? (
									<button
										type="button"
										className="hidden sm:inline-block mr-1 focus:outline-none"
										onClick={() => {
											adjustSampleBottleQuantity(product.id, quantityRef, sampleBottleQuantity - 1)
										}}
									>
										<Icon name="Minus" size="1x" className="inline-block text-gray-600" />
									</button>
								) : null}
								<div className="inline-block text-center">
									{controls ? (
										<input
											className="block sm:inline-block pt-1 border border-gray-400 px-2 w-16 text-center"
											ref={quantityRef}
											type="text"
											defaultValue={formatNumber(sampleBottleQuantity)}
											onChange={(e) => {
												let value = parseInt(e.currentTarget.value.replaceAll(/[^\d]/g, ""), 10)
												if (isNaN(value)) {
													value = 1
												}
												adjustSampleBottleQuantity(product.id, quantityRef, value)
											}}
											onFocus={(e) => {
												e.currentTarget.select()
											}}
										/>
									) : (
										formatNumber(sampleBottleQuantity)
									)}
									<div className="inline-block mt-1 sm:hidden space-x-2">
										{controls ? (
											<button
												type="button"
												className="focus:outline-none"
												onClick={() => {
													adjustSampleBottleQuantity(
														product.id,
														quantityRef,
														sampleBottleQuantity - 1,
													)
												}}
											>
												<Icon name="Minus" size="lg" className="inline-block text-gray-600" />
											</button>
										) : null}
										{controls ? (
											<button
												type="button"
												className="focus:outline-none"
												onClick={() => {
													adjustSampleBottleQuantity(
														product.id,
														quantityRef,
														sampleBottleQuantity + 1,
													)
												}}
											>
												<Icon name="Plus" size="lg" className="inline-block text-gray-600" />
											</button>
										) : null}
									</div>
								</div>
								{controls ? (
									<button
										type="button"
										className="hidden sm:inline-block ml-1 focus:outline-none"
										onClick={() => {
											adjustSampleBottleQuantity(product.id, quantityRef, sampleBottleQuantity + 1)
										}}
									>
										<Icon name="Plus" size="1x" className="inline-block text-gray-600" />
									</button>
								) : null}
							</td>
						</tr>
					)
				})}
				<tr className="border-t border-gray-400">
					<td className="hidden md:table-cell"></td>
					<td></td>
					<td className="text-right pt-3 pb-2">
						<span className={controls ? "mr-5 sm:mr-11" : ""}>{formatNumber(totalQuantity)}</span>
					</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	)
}
