import React from "react"
import { BrowserRouter, Switch, Route, Redirect, withRouter, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { slugify } from "./util"
import { State } from "@app/state"

import {
	BasicPage,
	BasketShowPage,
	BlogPage,
	BlogPostPage,
	CheckoutPage,
	ContactPage,
	HomePage,
	HTTPErrorPage,
	LoginPage,
	LogoutPage,
	PrivacyPolicyPage,
	ProductDetailPage,
	ProductListPage,
	ProductPriceListPage,
	ProductNutritionPage,
	RegisteredPage,
	SampleRequestPage,
} from "./pages"
import { ErrorProvider } from "@app/contexts"

const ScrollToTop = withRouter(({ history }) => {
	const { pathname } = useLocation()
	const [lastPath, setLastPath] = React.useState(pathname)
	React.useEffect(() => {
		const unlisten = history.listen(() => {
			if (lastPath !== pathname) {
				window.scrollTo(0, 0)
				setLastPath(pathname)
			}
		})
		return () => {
			unlisten()
		}
	}, [])
	return null
})

export const useQuery = () => {
	const params = new URLSearchParams(useLocation().search)
	const query = {}
	for (const p of params) {
		if (p[0] === "q") {
			query["q"] = p[1]
		} else if (query[p[0]]) {
			query[p[0]].push(slugify(p[1]))
		} else {
			query[p[0]] = [slugify(p[1])]
		}
	}
	return query
}

export const Router = () => {
	return (
		<BrowserRouter>
			<ErrorProvider>
				<State />
				<ScrollToTop />
				<Helmet>
					<title>Collaboration Wine Exports</title>
				</Helmet>
				<Switch>
					<Route exact={true} path="/" component={HomePage} />
					<Route exact={true} path="/about" component={BasicPage("about")} />
					<Route exact={true} path="/basket" component={BasketShowPage} />
					<Route exact={true} path="/blog" component={BlogPage} />
					<Route exact={true} path="/blog/:title/:id" component={BlogPostPage} />
					<Route exact={true} path="/cellar" component={() => <Redirect to="/shop" />} />
					<Route exact={true} path="/checkout" component={CheckoutPage} />
					<Route exact={true} path="/contact" component={ContactPage} />
					<Route exact={true} path="/help" component={BasicPage("help")} />
					<Route exact={true} path="/help" component={BasicPage("help")} />
					<Route exact={true} path="/login" component={LoginPage} />
					<Route exact={true} path="/login/:token" component={LoginPage} />
					<Route exact={true} path="/logout" component={LogoutPage} />
					<Route exact={true} path="/privacy-policy" component={PrivacyPolicyPage} />
					<Route exact={true} path="/registered" component={RegisteredPage} />
					<Route exact={true} path="/samples" component={SampleRequestPage} />
					<Route exact={true} path="/shop" component={ProductListPage} />
					<Route exact={true} path="/price-list" component={ProductPriceListPage} />
					<Route exact={true} path="/shop/wines/:id/:name" component={ProductDetailPage} />
					<Route exact={true} path="/nutrition/wines/:id" component={ProductNutritionPage} />
					<Route exact={true} path="/terms-of-use" component={BasicPage("terms-of-use")} />

					<Route>
						<HTTPErrorPage code={404} error="Page not found" />
					</Route>
				</Switch>
			</ErrorProvider>
		</BrowserRouter>
	)
}
