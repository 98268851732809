import React from "react"

import { Icon } from "@app/components"

export const RegisteredPage = () => {
	return (
		<>
			<img
				className="absolute inset-0 h-full w-full object-cover"
				src="/assets/img/adele-payman-2oYMwuFgnTg-unsplash.jpg"
				alt="vineyard"
			/>
			<div className="absolute inset-0 h-full w-full opacity-50 bg-white" />
			<div className="absolute inset-0">
				<div className="flex min-h-screen">
					<div className="flex-1 flex flex-col justify-center">
						<div className="mx-auto w-full max-w-xs text-center">
							<a href="/shop">
								<img
									className="opacity-90 mt-6 px-10"
									src="/assets/img/cwe-logo-large.png"
									alt="vineyard"
								/>
							</a>
							<p className="text-black opacity-90 mt-8 text-xl sm:text-2xl">
								Thanks for registering!
							</p>
							<p className="text-black opacity-90 mt-4 text-md sm:text-lg">
								You should receive an email within 24 hours with a magic link which logs you into
								the shop. Clicking on the link grants you access to pricing, ordering, and sample
								requests.
							</p>
							<p className="text-black opacity-90 mt-8">
								<a href="https://www.instagram.com/collabwinex">
									<Icon name="Instagram" size="2x" />
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="absolute bottom-0 right-0 text-right">
				<p className="text-black opacity-50 text-xs leading-8 font-extrabold tracking-tight mr-2">
					<a href="https://unsplash.com/@adele_payman">Photo by Adele Payman</a>
				</p>
			</div>
		</>
	)
}
