import React from "react"
import _ from "lodash"
import { Redirect } from "react-router-dom"
import { RichText } from "prismic-reactjs"

import { OrderItems, Input, TextArea, Label, Icon } from "@app/components"
import { MainLayout } from "@app/layouts"
import { useSession } from "@app/contexts"
import { usePrismic } from "@app/hooks"

export const CheckoutPage = () => {
	const { rpc, loggedIn, loading, basket, clearBasket, basketID } = useSession()
	const { snippets } = usePrismic()
	const willSubmitOrder = _.get(snippets, "willSubmitOrder")
	const didSubmitOrder = _.get(snippets, "didSubmitOrder", "")

	const [user, setUser] = React.useState()
	const [redirect, setRedirect] = React.useState()
	const [submitted, setSubmitted] = React.useState(false)
	const [sending, setSending] = React.useState(false)
	const [params, setParams] = React.useState({
		billingAddress: "",
		freightForwarder: "",
		shippingAddress: "",
		labelsRequired: false,
		vatNumber: "",
	})

	const keyDownHandler = (e) => {
		if (submitted && e.key === "Escape") {
			setRedirect("/shop")
		}
	}

	React.useEffect(() => {
		window.addEventListener("keydown", keyDownHandler)
		return () => {
			window.removeEventListener("keydown", keyDownHandler)
		}
	}, [])

	React.useEffect(() => {
		let mounted = true
		const load = async () => {
			const result = await rpc("WhoAmI")
			if (mounted && result.ok) {
				setUser(result.user)
			}
		}
		load()
		return () => {
			mounted = false
		}
	}, [])

	if (loading) {
		return null
	} else if (!loggedIn() || (_.isEmpty(basket.items) && !submitted)) {
		return <Redirect to="/shop" />
	} else if (!user) {
		return null
	}

	if (redirect) {
		return <Redirect to={redirect} />
	}

	const inputHandler = (name) => {
		return (e) => {
			const value = e.currentTarget.value
			setParams((prev) => _.set({ ...prev }, name, value))
		}
	}

	const checkboxHandler = (name) => {
		return (e) => {
			const value = e.currentTarget.checked
			setParams((prev) => _.set({ ...prev }, name, value))
		}
	}

	const sendOrderHandler = () => {
		const sendOrder = async (order) => {
			setSending(true)
			const result = await rpc("SubmitOrder", order)
			if (result.ok) {
				setSubmitted(true)
				clearBasket()
			}
			setSending(false)
		}
		sendOrder({
			...params,
			basketID,
			items: _.map(basket.items, (item) => {
				return {
					id: _.get(item, "product.id"),
					product: _.get(item, "product"),
					quantity: _.get(item, "quantity"),
				}
			}),
		})
	}

	if (submitted) {
		return (
			<MainLayout page="checkout">
				<div className="flex mx-auto container justify-center my-16">
					<div className="w-full mx-4 text-gray-800 text-lg">
						<div className="mx-auto text-center max-w-sm prismic">
							<RichText render={didSubmitOrder} />
						</div>
					</div>
				</div>
			</MainLayout>
		)
	}

	return (
		<>
			{submitted ? (
				<>
					<div
						className="fixed z-40 w-full h-screen bg-black opacity-75"
						onClick={() => {
							setRedirect("/shop")
						}}
					/>
					<div className="fixed z-50 w-full min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 pointer-events-none">
						<div className="max-w-sm w-full bg-white rounded-lg px-6 pt-6 pb-3 shadow-xl pointer-events-auto"></div>
					</div>
				</>
			) : null}
			<MainLayout page="checkout">
				<div className="flex mx-auto container max-w-6xl justify-center mt-16">
					<div className="w-full mx-4 text-gray-800">
						<OrderItems controls={false} />
					</div>
				</div>
				<div className="flex mx-auto container max-w-6xl mb-16">
					<div className="w-full mx-4 text-gray-800">
						<div className="px-4 grid sm:grid-cols-2 md:grid-cols-3 gap-8 my-6">
							<div>
								<Label>Name</Label>
								<Input readOnly value={user.name} />
								<div className="mt-4">
									<Label>Email</Label>
									<Input readOnly value={user.email} />
								</div>
							</div>
							<div>
								<Label>Billing address</Label>
								<TextArea onChange={inputHandler("billingAddress")} disabled={sending} />
								<p className="text-sm mt-1 px-2 text-gray-600"></p>
							</div>
							<div>
								<Label>Shipping address</Label>
								<TextArea onChange={inputHandler("shippingAddress")} disabled={sending} />
								<p className="text-sm mt-1 px-2 text-gray-600">Consignee/final destination.</p>
							</div>
							<div>
								<Label>Preferred freight forwarder</Label>
								<TextArea onChange={inputHandler("freightForwarder")} disabled={sending} />
								<p className="text-sm mt-1 px-2 text-gray-600">
									Optional. Please include freight forwarder name, email, and phone number.
								</p>
							</div>
							<div className="space-y-4">
								<div>
									<Label>VAT/Excise number</Label>
									<input
										className="w-full px-2 pt-2 pb-1 border border-gray-300 block"
										placeholder="1234567-1"
										onChange={inputHandler("vatNumber")}
										disabled={sending}
									/>
								</div>
								<div>
									<input
										className="inline-block mr-2"
										placeholder="1234567-1"
										type="checkbox"
										name="labelsRequired"
										id="labelsRequired"
										onChange={checkboxHandler("labelsRequired")}
										disabled={sending}
									/>
									<Label inline htmlFor="labelsRequired">
										Strip/case labels required?
									</Label>
								</div>
							</div>
							<div className="bg-gray-200 p-4 rounded">
								<div className="max-w-sm text-md prismic">
									<RichText render={willSubmitOrder} />
								</div>
								<div className="mt-2 text-right">
									<button
										type="button"
										className="bg-black text-white rounded-full px-4 pt-2 pb-1 mb-2 hover:bg-gray-800 focus:outline-none"
										onClick={sendOrderHandler}
										disabled={sending}
									>
										Submit order
										<Icon
											className="ml-2"
											name={sending ? "Loading" : "ChevronRight"}
											spin={sending}
											size="sm"
											fixedWidth
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MainLayout>
		</>
	)
}
