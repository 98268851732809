import React from "react"

export const TextArea = (props) => {
	return (
		<textarea
			className="w-full px-2 pt-2 pb-1 border border-gray-300 block leading-5"
			rows="4"
			{...props}
		></textarea>
	)
}
