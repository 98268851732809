import React from "react"
import _ from "lodash"
import { parseJSON, format } from "date-fns"
import Prismic from "@prismicio/client"

import { MainLayout } from "@app/layouts"
import { Link } from "@app/components"
import { client } from "@app/prismic"

export const BlogPage = () => {
	const [posts, setPosts] = React.useState()
	const loading = _.isEmpty(posts)

	React.useEffect(() => {
		let mounted = true
		const load = async () => {
			const resp = await client.query([Prismic.Predicates.at("document.type", "blog_post")], {
				orderings: "[document.first_publication_date desc]",
			})
			if (resp.results && mounted) {
				setPosts(resp.results)
			}
		}
		load()
		return () => {
			mounted = false
		}
	}, [])

	if (loading) {
		return null
	}

	return (
		<MainLayout page="blog">
			{_.map(posts, (post) => {
				const title = _.get(post, "data.title[0].text", "")
				const uid = _.get(post, "uid", "")
				const id = _.get(post, "id", "")
				const summary = _.get(post, "data.summary[0].text")
				const date = parseJSON(_.get(post, "first_publication_date"))
				return (
					<div key={id} className="mx-auto max-w-md px-4 py-6">
						<Link
							className="text-gray-900 hover:text-gray-700 hover:underline text-4xl"
							to={`/blog/${uid}/${id}`}
						>
							{title}
						</Link>
						<div className="text-gray-600 text-sm mb-4">{format(date, "MMM dd, yyyy")}</div>
						<div className="prismic">
							{summary}
							<span className="mx-1">—</span>
							<Link className="inline-block hover:underline" to={`/blog/${uid}/${id}`}>
								Read more
							</Link>
						</div>
					</div>
				)
			})}
		</MainLayout>
	)
}
