import React from "react"
import _ from "lodash"
import { useHistory } from "react-router-dom"

import { HTTPErrorPage } from "@app/pages"

const httpStatusContext = React.createContext({
	setHTTPStatus: _.noop,
})

export const ErrorProvider = ({ children }) => {
	const history = useHistory()
	const [httpStatus, setHTTPStatus] = React.useState()

	React.useEffect(() => {
		const unlisten = history.listen(() => setHTTPStatus(undefined))
		return unlisten
	}, [])

	const renderContent = () => {
		if (httpStatus === 404) {
			return <HTTPErrorPage code={404} error="Page not found" />
		}
		return children
	}

	const payload = React.useMemo(() => ({ setHTTPStatus }), [setHTTPStatus])

	return <httpStatusContext.Provider value={payload}>{renderContent()}</httpStatusContext.Provider>
}

export const useHTTPStatus = () => React.useContext(httpStatusContext)
