import React from "react"

export const Label = ({ children, inline, className = "", htmlFor }) => {
	return (
		<label
			htmlFor={htmlFor}
			className={`${
				inline ? "inline-block" : "block"
			} leading-5 text-lg text-gray-800 ${className}`}
		>
			{children}
		</label>
	)
}
