import React from "react"
import _ from "lodash"
import { RichText } from "prismic-reactjs"

import { Icon } from "@app/components"
import { useGlobalState } from "@app/state"
import { useSession } from "@app/contexts"
import { usePrismic } from "@app/hooks"

export const LoginBanner = () => {
	const { loggedIn } = useSession()
	const { snippets } = usePrismic()

	const shopNotLoggedInMessage = _.get(snippets, "shopNotLoggedIn")
	const [, setShowLogin] = useGlobalState("showLogin")

	return !_.isEmpty(shopNotLoggedInMessage) && !loggedIn() ? (
		<div className="bg-gray-300 mt-4 py-3">
			<div className="rounded text-white bg-gray-700 flex container py-4 px-8 mx-auto text-white max-w-xl space-x-4">
				<div className="flex flex-row space-x-4 items-center">
					<div className="prismic-inverse">
						<RichText render={shopNotLoggedInMessage} />
					</div>
					<div>
						<button
							className="float-right rounded-full py-1 px-4 inline-flex items-center text-black bg-white hover:bg-black hover:text-white"
							type="button"
							onClick={() => setShowLogin(true)}
						>
							<Icon name="Login" size="sm" className="mr-1" />
							<span className="pt-0.5">Login</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	) : null
}
