import React from "react"
import _ from "lodash"

import { usePrismic } from "@app/hooks"

const placeholderSrc =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAACdJREFUCB1j9Pf3/88ABMmMjCCKgQlMIhGMu3btAquY9mMDWBhDBQAutwfDrUlKzQAAAABJRU5ErkJggg=="
export const BannerImage = ({ page }) => {
	const { pages } = usePrismic(page)
	const doc = _.get(pages, page)

	const tagline = _.get(doc, "data.tagline", "")
	const src = _.get(doc, "data.banner_image.url", placeholderSrc)
	const alt = _.get(doc, "data.banner_image.alt", "")

	return (
		<div
			className="relative h-28 w-full bg-no-repeat bg-cover"
			style={{ backgroundImage: `url(${src})` }}
		>
			<div className="flex items-center justify-center text-center h-28 w-full">
				<p className="inline-block leading-6 align-bottom opacity-90 text-white px-8 text-2xl font-medium tracking-tight md:text-3xl">
					<span
						style={{
							textShadow: "0px 0px 25px rgba(0,0,0,0.5), 0px 0px 5px rgba(0,0,0,0.5)",
						}}
					>
						{tagline}
					</span>
				</p>
			</div>
			<div className="relative bottom-8 right-0 text-right w-full h-8">
				<p className="text-white opacity-50 text-xs leading-8 tracking-tight mr-2">{alt}</p>
			</div>
		</div>
	)
}
