import React from "react"

import { Router } from "@app/router"
import { SessionContext } from "@app/contexts"

export const App = () => {
	return (
		<SessionContext>
			<Router />
		</SessionContext>
	)
}
