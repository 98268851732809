import React from "react"

import { Link, Icon } from "@app/components"

export const HTTPErrorPage = (props) => {
	const { code = 500, error = "Internal Server Error" } = props

	return (
		<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 bg-gray-100 text-center sm:block sm:p-0">
			<span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
			<div className="inline-block align-bottom px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
				<div className="block text-center">
					<div className="inline-block text-black">
						<Link to="/">
							<img
								className="block h-8 sm:h-9 w-auto"
								src="/assets/img/cwe-logo-minimal.svg"
								alt="Collaboration Wine Exports"
							/>
						</Link>
					</div>
				</div>
				<div className="mt-3 text-center sm:mt-5 border-t border-gray-400 pt-6">
					<h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-headline">
						<Icon name="Exclamation" size="sm" className="mr-1 text-gray-500" />
						{`HTTP ${code} — ${error}`}
					</h3>
				</div>
			</div>
		</div>
	)
}
