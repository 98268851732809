import React from "react"
import _ from "lodash"
import { Redirect } from "react-router-dom"

import { Icon, Link } from "@app/components"
import { useSession } from "@app/contexts"
import { any } from "@app/util"

export const ProductCard = (props) => {
	const { product = {} } = props
	const {
		highlights = {
			aroma: product.aroma,
			caseFormat: product.caseFormat,
			brandName: product.brandName,
			crafted: product.craftedNotes,
			enjoying: product.enjoyingNotes,
			finish: product.finish,
			name: product.name,
			notes: product.notes,
			select: product.select,
			promotions: product.promotionsNotes,
			rating: product.ratingNotes,
			taste: product.taste,
			vintage: product.vintage,
		},
	} = props

	const { loggedIn, addToBasket, productsInBasket } = useSession()
	const [redirect, setRedirect] = React.useState()

	if (redirect) {
		return <Redirect push to={redirect} />
	}

	const visiblePromotions = _.filter(product.promotions, (promo) => !promo.hide)

	return (
		<div className="max-w-xs mx-auto text-center">
			<div className="px-6 pb-8 md:px-8">
				<Link to={`/shop/wines/${product.id}/${product.nameSlug}`}>
					<img
						className="h-32 flex-shrink-0 mx-auto bg-white"
						src={_.first(product.thumbnails)}
						alt=""
						loading="lazy"
					/>
				</Link>
				<h3 className="mt-6 mb-1 text-lg leading-5">
					<Link to={`/shop/wines/${product.id}/${product.nameSlug}`}>
						<span className="text-base" dangerouslySetInnerHTML={{ __html: highlights.vintage }} />
						<br />
						<span dangerouslySetInnerHTML={{ __html: highlights.name }} />
					</Link>
				</h3>
				{!loggedIn() && (
					<div className="text-gray-700 text-xs">
						<span dangerouslySetInnerHTML={{ __html: highlights.caseFormat }} />
					</div>
				)}
				{loggedIn() ? (
					_.isNumber(product.price) && !product.hidePrice ? (
						<div className="mt-1 flex flex-row justify-center">
							<button
								className="group bg-black text-white rounded-full text-base items-center justify-center px-4 pt-1 pb-0.5 mb-2 hover:bg-gray-800 focus:outline-none"
								onClick={() => {
									if (productsInBasket[product.id]) {
										setRedirect("/basket")
									} else {
										addToBasket(product)
									}
								}}
							>
								<span className="text-xs text-black group-hover:text-white">USD</span>
								{`$ ${product.price.toLocaleString()}`}
								<span className="text-xs text-gray-400">&nbsp;/&nbsp;</span>
								<span
									className="text-xs"
									dangerouslySetInnerHTML={{ __html: highlights.caseFormat }}
								/>
								{productsInBasket[product.id] ? (
									<Icon name="Check" size="sm" className="ml-2 text-green-400" />
								) : (
									<Icon
										name="CartPlus"
										size="sm"
										className="ml-2 text-black group-hover:text-white"
									/>
								)}
							</button>
						</div>
					) : (
						<div className="mt-1 flex flex-row justify-center">
							<button className="bg-gray-500 text-gray-300 rounded-full items-center justify-center px-4 pt-1 pb-0.5 mb-2 text-xs">
								Inquire about pricing and availability
							</button>
						</div>
					)
				) : null}
				{product.hasNotes ? (
					<div className="text-left text-sm my-1 pt-1 pb-1 text-gray-700 border-t border-gray-400">
						{product.notes ? (
							<div className="italic py-2 text-center text-gray-900">
								<span dangerouslySetInnerHTML={{ __html: highlights.notes }} />
							</div>
						) : null}
						{product.aroma ? (
							<div>
								<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
								<span dangerouslySetInnerHTML={{ __html: highlights.aroma }} />
							</div>
						) : null}
						{product.taste ? (
							<div>
								<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
								<span dangerouslySetInnerHTML={{ __html: highlights.taste }} />
							</div>
						) : null}
						{product.finish ? (
							<div>
								<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
								<span dangerouslySetInnerHTML={{ __html: highlights.finish }} />
							</div>
						) : null}
					</div>
				) : null}
				{any([visiblePromotions, product.crafted, product.enjoying > 0]) ? (
					<div className="text-left my-1 pt-2 border-t border-gray-400">
						{_.isEmpty(visiblePromotions) ? null : (
							<div className="block text-sm bg-yellow-200 text-black -mx-2 pt-1 px-2 pb-0.5 mb-1 rounded">
								<Icon name="Promotion" size="sm" className="mr-1 text-yellow-500" />
								<span dangerouslySetInnerHTML={{ __html: highlights.promotions }} />
							</div>
						)}
						{!product.select ? null : (
							<div className="block text-sm text-gray-700">
								<Icon name="Heart" size="sm" className="mr-1 text-red-700" fixedWidth />
								<span dangerouslySetInnerHTML={{ __html: highlights.select }} />
							</div>
						)}
						{_.isEmpty(product.rating) ? null : (
							<div className="block text-sm text-gray-700">
								<Icon name="Medal" size="sm" className="mr-1" fixedWidth />
								<span dangerouslySetInnerHTML={{ __html: highlights.rating }} />
							</div>
						)}
						{_.isEmpty(product.crafted) ? null : (
							<div className="block text-sm text-gray-700">
								<Icon name="Crafted" size="sm" className="mr-1" fixedWidth />
								<span dangerouslySetInnerHTML={{ __html: highlights.crafted }} />
							</div>
						)}
						{_.isEmpty(product.enjoying) ? null : (
							<div className="block text-sm text-gray-700">
								<Icon name="Enjoying" size="sm" className="mr-1" fixedWidth />
								<span dangerouslySetInnerHTML={{ __html: highlights.enjoying }} />
							</div>
						)}
					</div>
				) : null}
			</div>
		</div>
	)
}
