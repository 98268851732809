import React from "react"
import _ from "lodash"
import queryString from "query-string"

import { formatNumber, Storage } from "@app/util"

const query = queryString.parse(location.search)
const initialBasket = { items: [], totals: { quantity: 0, sampleBottleQuantity: 1, totalPrice: 0 } }
const initialAuthToken = Storage.get("authToken") || query["authToken"]
const initialBasketID = Storage.get("basketID")
const initialAcceptCookies = !!Storage.get("acceptCookies")

const sessionContext = React.createContext({
	acceptCookies: initialAcceptCookies,
	addToBasket: _.noop,
	adjustBasketQuantity: _.noop,
	adjustSampleBottleQuantity: _.noop,
	authToken: initialAuthToken,
	basket: initialBasket,
	basketID: initialBasketID,
	clearBasket: _.noop,
	loading: true,
	loggedIn: () => false,
	logout: _.noop,
	productsInBasket: {},
	removeFromBasket: _.noop,
	rpc: _.noop,
	setAcceptCookies: _.noop,
	setAuthToken: _.noop,
	setBasket: _.noop,
	setShowShopWelcome: _.noop,
	showShopWelcome: false,
})

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const calcBasketTotal = (items) => {
	return _.reduce(
		items,
		(acc, item) => {
			acc["quantity"] += item.quantity
			acc["totalPrice"] += item.quantity * item.price
			return acc
		},
		{ quantity: 0, totalPrice: 0 },
	)
}

export const SessionContext = ({ children }) => {
	const [acceptCookies, setAcceptCookies] = React.useState(initialAcceptCookies)
	const [authToken, setAuthToken] = React.useState(initialAuthToken)
	const [basket, setBasket] = React.useState(initialBasket)
	const [basketID, setBasketID] = React.useState(initialBasketID)
	const [productsInBasket, setProductsInBasket] = React.useState({})
	const [shouldGetBasket, setShouldGetBasket] = React.useState(true)
	const [showShopWelcome, setShowShopWelcome] = React.useState(false)
	const [loading, setLoading] = React.useState(true)

	const rpc = async (method, params = {}, options = { minDuration: 0 }) => {
		const waitUntil = Date.now() + options.minDuration
		let hint = ""

///////////////
///////////////////////////
/////////////////////////////////////
////////////////////
////////////////////////
////////////////////////
//////////////////////
/////////////////
//////
////////////////////////
//////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////
///////////////////////
///////
///////////////////////////////////
//////
/////////////////////////
/////////////////
//////
///////////////////////////////////////////////////////////
/////////////////
//////
///////////////////////
///////
//////////////
//////////////////////////////////
////////////////
///
////////////

		const body = {
			id: _.uniqueId(),
			method,
			params,
		}
		const headers = {
			"Content-Type": "application/json",
		}
		if (!_.isEmpty(authToken)) {
			headers["X-Session-Token"] = authToken
		}
		const resp = await fetch(`/rpc?${method}(${hint})`, {
			method: "post",
			body: JSON.stringify(body),
			headers: headers,
		})
		if (resp.status !== 200) {
			throw new Error("expected status code")
		}
		const data = await resp.json()
		if (_.has(data, "error")) {
			return { ok: false }
		}
		const wait = waitUntil - Date.now()
		if (wait > 0) {
			await timeout(wait)
		}
		return _.get(data, "result")
	}

	const clearBasket = () => {
		setBasketID(undefined)
		setBasket(initialBasket)
	}

	const removeFromBasket = (product) => {
		setBasket(() => {
			const items = _.filter(basket.items, (item) => item.product.id != product.id)
			return { items, totals: calcBasketTotal(items) }
		})
	}

	const addToBasket = (product) => {
		var found = false
		let items = [...basket.items]
		_.each(items, (item) => {
			if (item.product.id == product.id) {
				found = true
				return false
			}
		})
		let perTier
		if (_.startsWith(product.caseFormat, "6x")) {
			perTier = 28
		} else if (_.startsWith(product.caseFormat, "12x")) {
			perTier = 14
		} else {
			perTier = 1
		}
		if (!found) {
			items = [
				{
					product: product,
					quantity: perTier,
					sampleBottleQuantity: 1,
				},
			].concat(items)
		}
		setBasket({ items, totals: calcBasketTotal(items) })
		return !found
	}

	const adjustBasketQuantity = (productID, ref, quantity) => {
		setBasket((prev) => {
			const items = [...prev.items]
			_.each(items, (item) => {
				if (item.product.id === productID) {
					if (ref) {
						ref.current.value = formatNumber(Math.max(1, quantity))
					}
					item.quantity = Math.max(1, quantity)
					return false
				}
			})
			return { items, totals: calcBasketTotal(items) }
		})
	}

	const adjustSampleBottleQuantity = (productID, ref, quantity) => {
		setBasket((prev) => {
			const items = [...prev.items]
			_.each(items, (item) => {
				if (item.product.id === productID) {
					if (ref) {
						ref.current.value = formatNumber(Math.max(0, quantity))
					}
					item.sampleBottleQuantity = Math.max(0, quantity)
					return false
				}
			})
			return { items, totals: calcBasketTotal(items) }
		})
	}

	const logout = () => {
		Storage.clear()
		setAcceptCookies(undefined)
		clearBasket()
		setAuthToken(undefined)
		setShouldGetBasket(true)
	}

	const saveBasket = React.useRef(
		_.debounce(async (params) => {
			const result = await rpc("SaveBasket", params)
			if (result.ok) {
				setBasketID(_.get(result, "basket.id"))
			} else {
				setBasketID("")
			}
		}, 500),
	)

	React.useEffect(() => {
		setProductsInBasket(
			_.reduce(
				basket.items,
				(acc, item) => {
					acc[item.product.id] = true
					return acc
				},
				{},
			),
		)

		const shouldSave = !shouldGetBasket && !(_.isEmpty(basket.items) && _.isEmpty(basketID))
		if (shouldSave) {
			saveBasket.current({ id: basketID, ...basket })
		}
	}, [basket])

	React.useEffect(() => {
		Storage.set("basketID", basketID)
	}, [basketID])

	React.useEffect(() => {
		const getBasket = async () => {
			const result = await rpc("GetBasket", { id: basketID })
			if (result.ok) {
				setBasketID(_.get(result, "basket.id"))
				setBasket(_.get(result, "basket", initialBasket))
			} else {
				setBasketID("")
			}
			setShouldGetBasket(false)
			setLoading(false)
		}
		if (shouldGetBasket && !_.isEmpty(authToken)) {
			getBasket()
		} else {
			setLoading(false)
		}
	}, [authToken])

	return (
		<sessionContext.Provider
			value={{
				acceptCookies,
				addToBasket,
				adjustBasketQuantity,
				adjustSampleBottleQuantity,
				authToken,
				basket,
				basketID,
				clearBasket,
				loading,
				loggedIn: () => !_.isEmpty(authToken),
				logout,
				productsInBasket,
				removeFromBasket,
				rpc,
				setAcceptCookies,
				setAuthToken,
				setBasket,
				setShowShopWelcome,
				showShopWelcome,
			}}
		>
			{children}
		</sessionContext.Provider>
	)
}

export const useSession = () => React.useContext(sessionContext)
