import React from "react"
import _ from "lodash"
import { RichText } from "prismic-reactjs"

import { MainLayout } from "@app/layouts"
import { usePrismic } from "@app/hooks"
import { HTTPErrorPage } from "@app/pages"

export const BasicPage = (page) => {
	const basicPage = () => {
		const { pages, loading } = usePrismic()
		const doc = _.get(pages, page)
		const content = _.get(doc, "data.content")
		if (!loading && _.isNil(content)) {
			return <HTTPErrorPage code={404} error="Page not found" />
		}
		return (
			<MainLayout page={page}>
				<div className="prismic mx-auto max-w-md px-4 py-6">
					{content ? <RichText render={content} /> : null}
				</div>
			</MainLayout>
		)
	}
	return basicPage
}
