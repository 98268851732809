import React from "react"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faCartPlus,
	faCertificate,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleNotch,
	faClipboard,
	faCog,
	faCommentAlt,
	faDotCircle,
	faExclamationCircle,
	faFileDownload,
	faFilter,
	faGlassCheers,
	faHome,
	faMedal,
	faHeart,
	faMinusCircle,
	faPaperclip,
	faPaperPlane,
	faPlusCircle,
	faSearch,
	faSeedling,
	faShoppingCart,
	faSignInAlt,
	faSquare,
	faStar,
	faStarOfLife,
	faTimesCircle,
	faWineBottle,
	faWineGlassAlt,
} from "@fortawesome/free-solid-svg-icons"

import { faInstagram, faWeixin, faTwitter } from "@fortawesome/free-brands-svg-icons"

const Icons = {
	Asterisk: faStarOfLife,
	Basket: faShoppingCart,
	CartPlus: faCartPlus,
	Check: faCheck,
	ChevronDown: faChevronDown,
	ChevronLeft: faChevronLeft,
	ChevronRight: faChevronRight,
	ChevronUp: faChevronUp,
	Circle: faCircle,
	Crafted: faSeedling,
	DotCircle: faDotCircle,
	Email: faPaperPlane,
	Enjoying: faGlassCheers,
	Exclamation: faExclamationCircle,
	Filter: faFilter,
	Gear: faCog,
	Heart: faHeart,
	Home: faHome,
	Instagram: faInstagram,
	Loading: faCircleNotch,
	Login: faSignInAlt,
	Medal: faMedal,
	Minus: faMinusCircle,
	Note: faCommentAlt,
	Notes: faClipboard,
	Paperclip: faPaperclip,
	Plus: faPlusCircle,
	Promotion: faCertificate,
	Search: faSearch,
	Square: faSquare,
	Star: faStar,
	TastingNotes: faFileDownload,
	Times: faTimesCircle,
	Twitter: faTwitter,
	WeChat: faWeixin,
	WineBottle: faWineBottle,
	WineGlass: faWineGlassAlt,
}

export const Icon = (props) => {
	const { className = "", name = "", ...rest } = props
	const icon = _.get(Icons, name)
	return <FontAwesomeIcon className={className} icon={icon} {...rest} />
}
