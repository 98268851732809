import React from "react"
import _ from "lodash"
import { Redirect, useParams } from "react-router-dom"

import { useSession } from "@app/contexts"
import { Storage } from "@app/util"
import { useGlobalState } from "@app/state"

export const LoginPage = () => {
	let { token } = useParams()

	const { setAuthToken, setShowShopWelcome, rpc } = useSession()

	const [redirect, setRedirect] = React.useState()
	const [showLogin, setShowLogin] = useGlobalState("showLogin")

	React.useEffect(() => {
		let mounted = true
		const checkToken = async () => {
			const result = await rpc("LoginWithToken", { token })
			if (mounted && _.get(result, "ok")) {
				const authToken = _.get(result, "authToken")
				Storage.clear()
				Storage.set("authToken", authToken)
				setAuthToken(authToken)
				setShowShopWelcome(true)
				setRedirect("/shop")
			} else if (mounted) {
				setRedirect("/")
			}
		}
		if (!_.isEmpty(token)) {
			checkToken()
		} else {
			if (!showLogin) {
				setShowLogin(true)
			}
			setRedirect("/shop")
		}
		return () => {
			mounted = false
		}
	}, [])

	return redirect ? <Redirect to={redirect} /> : null
}
