import React from "react"
import _ from "lodash"
import { useLocation, useHistory } from "react-router-dom"

import { useGlobalState } from "@app/state"
import { Link, Icon } from "@app/components"
import { useSession } from "@app/contexts"

const Links = [
	{ label: "About", to: "/about" },
	{ label: "Blog", to: "/blog" },
	{ label: "Shop", to: "/shop" },
	{ label: "Price List", to: "/price-list", authed: true },
	{ label: "Contact", to: "/contact" },
]

export const Navigation = () => {
	const history = useHistory()

	const { loggedIn, basket } = useSession()
	const [showLogin, setShowLogin] = useGlobalState("showLogin")
	const { pathname } = useLocation()

	const basketHandler = () => {
		if (!loggedIn()) {
			setShowLogin(true)
		} else {
			history.push("/basket")
		}
	}

	return (
		<nav className="w-full bg-white">
			<div
				className={`${
					loggedIn() ? "fixed" : "absolute"
				} z-50 top-0 right-0 flex bg-white rounded-full mt-3 mr-3 shadow-xl`}
			>
				{loggedIn() ? (
					<Link
						to="/basket"
						style={{ borderColor: "rgba(0, 0, 0, 0.1)", borderWidth: "1px" }}
						className={`rounded-full py-1 px-4 inline-flex items-center ${
							_.startsWith(pathname, "/basket")
								? "text-white bg-black"
								: "hover:bg-black hover:text-white"
						}`}
						onClick={basketHandler}
					>
						<span className="sr-only">Basket</span>
						<Icon name="Basket" size="sm" />
						<span className={"ml-1 pt-1"}>{_.size(basket.items)}</span>
					</Link>
				) : (
					<Link
						to="/login"
						className={`rounded-full py-1 px-4 inline-flex items-center ${
							showLogin ? "text-white bg-black" : "hover:bg-black hover:text-white"
						}`}
						style={{ borderColor: "rgba(0, 0, 0, 0.1)", borderWidth: "1px" }}
						onClick={(e) => {
							e.preventDefault()
							setShowLogin(!showLogin)
							return false
						}}
					>
						<Icon name="Login" size="sm" className="mr-1" />
						<span className="pt-0.5">Login</span>
					</Link>
				)}
			</div>

			<div className="">
				<div className="relative flex justify-between h-16">
					<div className="flex-1 flex items-center justify-center ">
						<div className="flex-shrink-0 flex items-center">
							<Link to="/">
								<img
									className="block h-8 w-auto"
									src="/assets/img/cwe-logo-minimal.svg"
									alt="Collaboration Wine Exports"
								/>
							</Link>
						</div>
					</div>
				</div>
				<div className="relative bg-black flex justify-between">
					<div className="text-white flex-1 flex items-center justify-center py-2">
						{_.map(Links, (v) => {
							if (v.authed && !loggedIn()) {
								return null
							}
							return (
								<div className="mx-1" key={v.to}>
									<Link
										to={v.to}
										className={`block font-medium px-2 md:px-4 pt-1 pb-0.5 rounded-full whitespace-nowrap ${
											_.startsWith(pathname, v.to)
												? "text-black bg-white"
												: "hover:bg-white hover:text-black"
										}`}
									>
										{v.label}
									</Link>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</nav>
	)
}
