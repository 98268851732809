import React from "react"
import _ from "lodash"

import { useGlobalState } from "@app/state"
import { Icon } from "@app/components"
import { useSession } from "@app/contexts"

export const Login = () => {
	const { rpc } = useSession()
	const [showLogin, setShowLogin] = useGlobalState("showLogin")

	const [email, setEmail] = React.useState("")
	const [loading, setLoading] = React.useState(false)
	const [success, setSuccess] = React.useState(false)
	const [error, setError] = React.useState(false)

	const keyDownHandler = (e) => {
		if (showLogin && e.key === "Escape") {
			setShowLogin(false)
		}
	}

	const submitHandler = async (e) => {
		e.preventDefault()
		setLoading(true)
		let result
		try {
			result = await rpc("LoginRequested", { email }, { minDuration: 750 })
		} catch (e) {
			setError("Something went wrong.")
		}
		if (_.get(result, "ok")) {
			setSuccess(true)
			setError(false)
		} else {
			setError("Sorry, we don't have an account with that email.")
		}
		setLoading(false)
		return false
	}

	React.useEffect(() => {
		window.addEventListener("keydown", keyDownHandler)
		return () => {
			window.removeEventListener("keydown", keyDownHandler)
		}
	}, [])

	return (
		<>
			<div
				className="fixed z-40 w-full h-screen bg-black opacity-75"
				onClick={() => {
					setShowLogin(false)
				}}
			/>
			<div className="fixed z-50 w-full min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 pointer-events-none">
				<div className="max-w-sm w-full bg-white rounded-lg p-6 shadow-xl pointer-events-auto">
					{success ? (
						<>
							<p className="mb-2 text-gray-700">Email sent!</p>
							<p className="text-sm">
								Check your email at <strong>{email}</strong>. You should have a login link in a few
								seconds!
							</p>
						</>
					) : (
						<>
							<h2 className="text-2xl leading-7 font-bold tracking-tight text-gray-900">Login</h2>
							{error ? (
								<p className="mt-2 text-blush-600 text-sm h-10">{error}</p>
							) : (
								<p className="mt-2 text-gray-700 text-base h-10">
									An account gives you access to pricing and the ability to create and submit
									orders.
								</p>
							)}
							<div className="mt-4">
								<form onSubmit={submitHandler} noValidate>
									<div className="mt-1">
										<label htmlFor="Email">Your email address</label>
										<input
											autoFocus
											disabled={loading}
											id="email"
											type="email"
											placeholder="you@example.com"
											required
											onChange={(e) => setEmail(e.target.value)}
											className={`appearance-none block w-full px-3 pt-2 pb-1 border border-gray-400 rounded-md placeholder-gray-400 focus:outline-none ${
												error ? "text-blush-600" : ""
											}`}
										/>
									</div>
									<div className="mt-2">
										<p className="text-sm text-gray-700 mb-2">
											{`We'll send you an email with a magic link that will log you in. No need for a
											password!`}
										</p>

										<button
											disabled={loading}
											type="submit"
											className="text-sm rounded-full pt-2 pb-1.5 px-4 text-white bg-black font-bold focus:outline-none hover:bg-gray-800"
										>
											Email link
											{loading ? (
												<Icon name="Loading" className="ml-1 text-gray-500" spin />
											) : (
												<Icon name="Email" className="ml-1" />
											)}
										</button>

										<p className="mt-4 text-sm">
											{"Don't have an account? "}
											<a
												target="_blank"
												rel="noreferrer"
												href="https://airtable.com/shruNLF8IZbL4wTK0"
												className="font-medium underline text-blush-500 hover:text-blush-500 focus:outline-none focus:underline transition ease-in-out duration-150"
											>
												Register
											</a>{" "}
											for one!
										</p>
									</div>
								</form>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	)
}
