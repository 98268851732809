import React from "react"
import _ from "lodash"
import { Redirect } from "react-router-dom"
import queryString from "query-string"

import { useGlobalState } from "@app/state"
import { Link, Icon } from "@app/components"
import { useSession } from "@app/contexts"

export const ProductPriceListPage = () => {
	const { loading, loggedIn, authToken } = useSession()

	const [avas] = useGlobalState("avas")
	const [brands] = useGlobalState("brands")
	const [certifications] = useGlobalState("certifications")
	let [products] = useGlobalState("products")

	if (!loggedIn()) {
		return <Redirect to="/shop" />
	} else if (loading) {
		return null
	}

	const query = queryString.parse(location.search)
	const { authToken: queryAuthToken = "" } = query

	products = _.filter(products, (p) => p.priceListEnabled)
	if (_.some([products, avas, brands], _.isEmpty)) {
		return null
	}
	const na = "n/a"
	const groupedByShipping = _.groupBy(products, "shippingPoint")

	return (
		<>
			{_.isEmpty(queryAuthToken) && (
				<div className="w-full flex justify-center">
					<a
						href={`/price-list.pdf?authToken=${encodeURIComponent(authToken)}`}
						className="mb-4 rounded-full font-sans bg-black text-white px-6 pb-1.5 pt-1 fixed bottom-0 hover:bg-gray-800 shadow-md"
					>
						<Icon fixedWidth name="Paperclip" className="mr-2 text-gray-400" />
						Download PDF
					</a>
				</div>
			)}
			<div id="price-list" className="mx-4 mb-4 text-xs">
				<div className="font-sans">
					<div className="flex justify-center m-6">
						<Link className="" to="/">
							<img src="/assets/img/cwe-logo-large.jpg" style={{ maxWidth: "8rem" }} />
						</Link>
					</div>
					<table className="table-auto w-full">
						{_.map(_.keys(groupedByShipping).sort(), (shippingPoint) => {
							const products = groupedByShipping[shippingPoint]
							const groupedByBrand = _.groupBy(products, "brandID")
							return (
								<tbody key={`${shippingPoint}-body`}>
									<tr key={`${shippingPoint}-head`}>
										<td
											className="pt-1 pb-0.5 tracking-normal font-semibold bg-brand border border-gray-300 text-center text-white text-2xl"
											colSpan="11"
										>
											{shippingPoint}
										</td>
									</tr>
									{_.map(groupedByBrand, (products, brandID) => [
										<tr key={`${brandID}-head`}>
											<td
												className="pt-1 pb-0.5 tracking-normal font-semibold bg-gray-200 border border-gray-300 text-center text-lg"
												colSpan="11"
											>
												{_.get(brands, `map[${brandID}].name`, "...")}
											</td>
										</tr>,
										<tr
											key={`${brandID}-cols`}
											className="text-xs bg-orange-100 text-center font-semibold"
										>
											<th className="border border-gray-300 px-2 py-1">Name</th>
											<th className="border border-gray-300 px-2 py-1">Vintage</th>
											<th className="border border-gray-300 px-2 py-1">AVA</th>
											<th className="border border-gray-300 px-2 py-1">Case&nbsp;Price</th>
											<th className="border border-gray-300 px-2 py-1">Case&nbsp;Format</th>
											<th className="border border-gray-300 px-2 py-1">Bottle&nbsp;Price</th>
											<th className="border border-gray-300 px-2 py-1">Accolades</th>
											<th className="border border-gray-300 px-2 py-1">Certificates</th>
										</tr>,
										_.map(
											_.sortBy(products, (p) => p.price),
											(p) => {
												const price = p.price ? `$ ${p.price}` : na
												const bottlesPerCase = _.first(p.caseFormat.split("x")) || 12
												const bottlePrice = p.price
													? `$ ${(p.price / bottlesPerCase).toFixed(2)}`
													: na
												const alcoholPercent = _.get(p, "alcoholPercent", 0)
												let alcohol
												if (alcoholPercent === 0) {
													alcohol = na
												} else {
													alcohol = `${(alcoholPercent * 100).toFixed(1)}%`
												}
												const sip = _.some(p.certifications, (c) => c.name == "SIP")
												return (
													<tr key={p.id}>
														<td className="border border-gray-300 bg-gray-100 px-2 py-1 whitespace-nowrap">
															{/*sip && (
															<Icon name="Asterisk" fixedWidth className="text-brand" size="xs" />
														)*/}
															{p.name}
														</td>
														<td className="border border-gray-300 px-2 py-1 text-center">
															{p.vintage}
														</td>
														<td className="border border-gray-300 px-2 py-1">
															{avas.map[p.avaID].name}
														</td>
														<td className="border border-gray-300 px-2 py-1 text-right">{price}</td>
														<td className="border border-gray-300 px-2 py-1">{p.caseFormat}</td>
														<td className="border border-gray-300 px-2 py-1 text-right">
															{bottlePrice}
														</td>
														<td className="border border-gray-300 px-2 py-1">{p.accolades}</td>
														<td className="border border-gray-300 px-2 py-1">
															{_.map(p.certifications, (c) => {
																return c.name
															}).join(", ")}
														</td>
													</tr>
												)
											},
										),
									])}
								</tbody>
							)
						})}
						<tbody>
							<tr>
								<td className="p-4 border border-gray-300" colSpan="11">
									<span className="font-semibold">Terms of Sale</span>:
									<br />
									Prices are Ex-Works Winery Warehouse, Net Collaboration Wine Exports LLC in USD.
									<br />
									Prices are subject to change and special packaging/labeling are subject to
									additional costs.
									<br />
									Minimum order requirement is: $3500.00 USD per order.
									<br />
									Some brands subject to market availability.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
