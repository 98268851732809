import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { MainLayout } from "@app/layouts"
import { Paragraphs } from "@app/components"
import { useGlobalState } from "@app/state"
import { useHTTPStatus } from "@app/contexts"
import { Helmet } from "react-helmet"

export const ProductNutritionPage = () => {
	let { id } = useParams()

	const { setHTTPStatus } = useHTTPStatus()
	const [imageIndex, setImageIndex] = React.useState(0)
	const [products] = useGlobalState("products")
	if (_.size(products) === 0) {
		return null
	}

	const product = _.find(products, (v) => v.id === id)
	if (_.isNil(product)) {
		setHTTPStatus(404)
		return null
	}

	if (_.trim(product.nutrition) === "") {
		setHTTPStatus(404)
		return null
	}

	const facts = product.nutrition.split("\n").map((fact) => {
		const [key, value] = _.trim(fact).split(": ")
		if (_.isNil(key)) {
			return null
		}
		if (_.isNil(value)) {
			return (
				<tr key={key}>
					<td className="p-2 text-center" colSpan={2}>
						{fact}
					</td>
				</tr>
			)
		}
		return (
			<tr key={key}>
				<td className="p-2">{key}</td>
				<td className="p-2">{value}</td>
			</tr>
		)
	})

	return (
		<MainLayout plain>
			<Helmet>
				<title>{`${product.vintage} ${product.name}`}</title>
			</Helmet>
			<section className="container mx-auto max-w-7xl mt-2 p-4 text-gray-900">
				<div className="flex flex-col md:flex-row md:space-x-8">
					<div id="description" className="">
						<h2 className="text-4xl mb-2 font-heading">
							<span className="text-3xl">{product.vintage}</span>
							<br />
							<span className="font-semibold">{product.name}</span>
						</h2>

						<div className="max-w-lg">
							<h2 className="text-2xl mt-4 mb-2 font-heading">Nutrition Facts</h2>
							<table
								id="details"
								className="w-full max-w-lg mt-4 mb-6 border-gray-400 border-t border-b"
							>
								<tbody className="divide-y divide-gray-400">{facts}</tbody>
							</table>
							<h2 className="text-2xl mt-4 mb-2 font-heading">Description</h2>
							<Paragraphs text={product.description} />
						</div>
					</div>
					<div id="gallery" className="bg-white max-w-xl mt-4 lg:mt-0 lg:max-w-auto lg:w-1/2">
						<div className="flex justify-center border-gray-400 border lg:p-8">
							<img
								className="object-none object-center object-scale-down"
								style={{ maxHeight: "50vh", minHeight: "50vh" }}
								src={product.images[imageIndex]}
								alt="Bottle or label image"
							/>
						</div>
						<div className="flex flex-row mt-4 space-x-4">
							{_.size(product.thumbnails) > 1
								? _.map(product.thumbnails, (image, i) => {
										return (
											<div
												key={image}
												className={`cursor-pointer border p-2 ${
													i === imageIndex ? "border-gray-800" : "border-gray-400"
												}`}
												onClick={() => {
													setImageIndex(i)
												}}
											>
												<img
													className="object-none object-center object-scale-down max-h-20 w-20"
													src={image}
													alt="Bottle or label image"
												/>
											</div>
										)
								  })
								: null}
						</div>
					</div>
				</div>
			</section>
		</MainLayout>
	)
}
