import React from "react"
import _ from "lodash"
import { Redirect } from "react-router-dom"

import { Icon, Link, OrderItems } from "@app/components"
import { MainLayout } from "@app/layouts"
import { useSession } from "@app/contexts"

export const BasketShowPage = () => {
	const { loggedIn, loading, basket } = useSession()

	if (!loggedIn()) {
		return <Redirect to="/shop" />
	} else if (loading) {
		return null
	}
	return (
		<MainLayout page="basket">
			<div className="flex mx-auto container max-w-6xl justify-center mt-16">
				<div className="w-full mx-4 text-gray-800">
					{_.isEmpty(basket.items) ? (
						<div className="text-center text-lg">
							<h3>Your basket is empty.</h3>
						</div>
					) : (
						<>
							<OrderItems />
							<div className="text-center mt-4 text-gray-700">
								<span className="text-red-500 text-xl">*</span>14 cases (12x750ml) is even tier
								shipping. You can manually enter other quantities, if desired.
							</div>
							<div className="text-center mt-4 text-gray-700">
								Prices are in USD, Ex Works (EXW) winery warehouse, Net Collaboration Wine Exports
								LLC.
							</div>
							<div className="text-center mt-4 text-gray-700">
								Questions about ordering? See our{" "}
								<Link className="underline" to="/help">
									help and FAQ
								</Link>
								.
							</div>
						</>
					)}
				</div>
			</div>
			{_.isEmpty(basket.items) ? null : (
				<div className="flex mx-auto container px-4 max-w-6xl mb-16 mt-4 justify-end">
					<Link
						to="/samples"
						className="bg-black text-white rounded-full px-4 pt-2 pb-1 mb-2 hover:bg-gray-800 focus:outline-none"
					>
						Request samples
						<Icon className="ml-2" name="ChevronRight" size="sm" />
					</Link>
					<span className="mx-4 pt-2 pb-1 mb-2">or</span>
					<Link
						to="/checkout"
						className="bg-black text-white rounded-full px-4 pt-2 pb-1 mb-2 hover:bg-gray-800 focus:outline-none"
					>
						Add order details
						<Icon className="ml-2" name="ChevronRight" size="sm" />
					</Link>
				</div>
			)}
		</MainLayout>
	)
}
