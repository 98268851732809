import React from "react"
import _ from "lodash"
import fuzzysort from "fuzzysort"
import Prismic from "prismic-javascript"

import { useGlobalState } from "@app/state"

const apiEndpoint = "https://collaborationwine.cdn.prismic.io/api/v2"
const accessToken =
	"MC5YOXoxMlJFQUFDSUEzaGFL.77-977-977-9Je-_vUvvv716Gu-_vX1CVyoNWO-_ve-_vR3vv73vv73vv70w77-9PGEc77-9U--_ve-_ve-_vQ"
const Client = Prismic.client(apiEndpoint, { accessToken })

export const usePrismic = () => {
	const [basicPages, setBasicPages] = useGlobalState("basicPages")
	const [snippets, setSnippets] = useGlobalState("snippets")
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		let mounted = true
		const load = async () => {
			const response = await Client.query(Prismic.Predicates.at("document.type", "basic_page"))
			if (response && mounted) {
				setBasicPages(
					_.reduce(
						response.results,
						(acc, v) => {
							acc[v.uid] = v
							return acc
						},
						{},
					),
				)
				setLoading(false)
			}
		}
		load()
		return () => {
			mounted = false
		}
	}, [])

	React.useEffect(() => {
		let mounted = true
		const load = async () => {
			const response = await Client.query(Prismic.Predicates.at("document.type", "snippets"))
			if (response && mounted) {
				setSnippets(
					_.reduce(
						response.results,
						(acc, v) => {
							acc[v.data.key] = v.data.content
							return acc
						},
						{},
					),
				)
			}
		}
		load()
		return () => {
			mounted = false
		}
	}, [])

	return { pages: basicPages, loading, snippets }
}

const highlight = (query, text) => {
	return text.replace(
		new RegExp(query, "gi"),
		(match) => `<span class="bg-yellow-300 text-black">${match}</span>`,
	)
}

export const useSearch = (defaultQuery = "") => {
	const [products] = useGlobalState("products")

	const [searchQuery, setSearchQuery] = React.useState(defaultQuery)
	const [documents, setDocuments] = React.useState([])
	const [searchResults, setSearchResults] = React.useState([])

	React.useEffect(() => {
		if (_.isEmpty(products)) {
			return
		}
		setDocuments(() =>
			_.map(products, (p) => {
				return {
					id: p.id,
					aroma: _.get(p, "aroma"),
					caseFormat: p.caseFormat,
					brandName: p.brandName,
					crafted: p.craftedNotes,
					enjoying: p.enjoyingNotes,
					finish: _.get(p, "finish"),
					name: p.name,
					notes: `${p.notes}`,
					promotions: p.promotionsNotes,
					select: p.select,
					rating: p.ratingNotes,
					taste: _.get(p, "taste"),
					varietals: p.varietalsNotes,
					vintage: `${p.vintage}`,
				}
			}),
		)
	}, [products])

	React.useEffect(() => {
		if (_.isEmpty(searchQuery) || _.isEmpty(documents)) {
			setSearchResults([])
			return
		}

		let timer = _.delay(() => {
			const options = {
				allowTypo: false,
				threshold: -256,
				keys: [
					"name",
					"brandName",
					"varietals",
					"vintage",
					"aroma",
					"taste",
					"notes",
					"finish",
					"rating",
					"select",
					"promotions",
					"enjoying",
					"crafted",
					"caseFormat",
				],
			}
			const results = _.map(fuzzysort.go(searchQuery, documents, options), (r) => {
				return {
					id: r.obj.id,
					score: r.score,
					highlights: {
						aroma: highlight(searchQuery, r.obj.aroma),
						finish: highlight(searchQuery, r.obj.finish),
						name: highlight(searchQuery, r.obj.name),
						taste: highlight(searchQuery, r.obj.taste),
						vintage: highlight(searchQuery, r.obj.vintage),
						notes: highlight(searchQuery, r.obj.notes),
						rating: highlight(searchQuery, r.obj.rating),
						enjoying: highlight(searchQuery, r.obj.enjoying),
						select: highlight(searchQuery, r.obj.select),
						crafted: highlight(searchQuery, r.obj.crafted),
						promotions: highlight(searchQuery, r.obj.promotions),
						caseFormat: highlight(searchQuery, r.obj.caseFormat),
					},
				}
			})
			setSearchResults(results)
		}, 300)

		return () => {
			clearTimeout(timer)
		}
	}, [searchQuery, documents])

	const searching = !_.isEmpty(searchQuery)

	return {
		documents,
		searching,
		searchQuery,
		searchResults,
		setSearchQuery,
	}
}
