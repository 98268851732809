import React from "react"
import { Redirect } from "react-router-dom"

import { useSession } from "@app/contexts"

export const LogoutPage = () => {
	const { logout, loggedIn } = useSession()

	const isLoggedIn = loggedIn()

	const [redirect, setRedirect] = React.useState()
	const [wasLoggedIn] = React.useState(isLoggedIn)

	React.useEffect(() => {
		if (isLoggedIn) {
			logout()
		} else {
			if (!wasLoggedIn) {
				setRedirect("/")
			} else {
				location.reload()
			}
		}
	}, [isLoggedIn])

	return redirect ? <Redirect to={redirect} /> : null
}
