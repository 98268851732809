import { Link as RouterLink } from "react-router-dom"

import React from "react"

export const Link = (props) => {
	const { className = "", ...rest } = props
	return (
		<RouterLink
			className={className ? className : "hover:text-gray-700 hover:underline"}
			{...rest}
		/>
	)
}
