import React from "react"
import _ from "lodash"
import { useParams, Redirect } from "react-router-dom"

import { MainLayout } from "@app/layouts"
import { LoginBanner, Paragraphs, Link, Icon } from "@app/components"
import { useGlobalState } from "@app/state"
import { useSession, useHTTPStatus } from "@app/contexts"
import { Helmet } from "react-helmet"

const na = <span className="text-gray-500">n/a</span>

export const ProductDetailPage = () => {
	let { id } = useParams()

	const { loggedIn, addToBasket, productsInBasket } = useSession()
	const { setHTTPStatus } = useHTTPStatus()
	const [redirect, setRedirect] = React.useState()
	const [imageIndex, setImageIndex] = React.useState(0)

	const [priceRange] = useGlobalState("priceRange")
	const [products] = useGlobalState("products")
	if (_.size(products) === 0) {
		return null
	}

	const product = _.find(products, (v) => v.id === id)

	if (_.isNil(product) || !product.shopEnabled) {
		setHTTPStatus(404)
		return null
	}

	const alcoholPercent = _.get(product, "alcoholPercent", 0)
	let alcohol
	if (alcoholPercent === 0) {
		alcohol = na
	} else {
		alcohol = `${(alcoholPercent * 100).toFixed(1)}%`
	}

	const bottlesPerCase = _.first(product.caseFormat.split("x")) || 12
	const bottlePrice = product.price ? (
		<>
			<span className="text-sm">USD</span>$ {(product.price / bottlesPerCase).toFixed(2)}
		</>
	) : (
		na
	)
	const casePrice = product.price ? (
		<>
			<span className="text-sm">USD</span>$ {product.price.toFixed(2)}
		</>
	) : (
		na
	)

	const retailBottlePrice =
		product.usAverageRetailPrice && !product.hidePrice ? (
			<>
				<span className="text-sm">USD</span>$ {product.usAverageRetailPrice.toFixed(2)}
			</>
		) : (
			na
		)

	const productPriceRange = () => {
		let min = product.price - 10
		min -= min % 10
		let max = product.price + 10
		max += 10 - (max % 10)
		return `${Math.max(priceRange.min, min)}+${Math.min(priceRange.max, max)}`
	}

	if (redirect) {
		return <Redirect push to={redirect} />
	}

	const visiblePromotions = _.filter(product.promotions, (promo) => !promo.hide)

	return (
		<MainLayout>
			<Helmet>
				<title>{`${product.vintage} ${product.name} — Collaboration Wine Exports`}</title>
			</Helmet>
			<LoginBanner />
			<section className="container mx-auto max-w-7xl mt-2 p-4 text-gray-900">
				<div className="flex flex-col md:flex-row md:space-x-8">
					<div id="description" className="">
						<h2 className="text-4xl mb-2 font-heading">
							<span className="text-3xl">{product.vintage}</span>
							<br />
							<span className="font-semibold">{product.name}</span>
						</h2>
						{product.price && !product.hidePrice && loggedIn() ? (
							<button
								className="group bg-black text-white rounded-full text-base items-center justify-center px-4 pt-1 mb-4 hover:bg-gray-800"
								onClick={() => {
									if (productsInBasket[product.id]) {
										setRedirect("/basket")
									} else {
										addToBasket(product)
									}
								}}
							>
								<span className="text-2xl">
									<span className="text-base text-gray-500">USD</span>
									{`$ ${product.price.toLocaleString()}`}
									<span className="text-sm text-gray-400">&nbsp;/&nbsp;</span>
									<span className="text-sm">{product.caseFormat}</span>
									{productsInBasket[product.id] ? (
										<>
											<Icon name="Check" size="sm" className="ml-2 text-green-400" />
											<span className="text-base ml-1">In basket</span>
										</>
									) : (
										<Icon name="CartPlus" size="sm" className="ml-2 text-black text-gray-500" />
									)}
								</span>
							</button>
						) : (
							<button className="group bg-gray-500 text-gray-300 rounded-full text-base items-center justify-center px-4 pt-1 mb-4">
								Inquire about pricing and availability
							</button>
						)}
						<div className="text-center max-w-lg text-lg text-black -mx-2 pt-1 px-2 pb-0.5 mb-2 rounded space-x-2">
							{_.isEmpty(visiblePromotions) ? null : (
								<span>
									<Icon name="Promotion" className="mr-1 text-yellow-500" />
									{_.map(visiblePromotions || [], "name").join(", ")}
								</span>
							)}
							{!product.select ? null : (
								<span>
									<Icon name="Heart" className="mr-1 text-red-700" />
									<span>CollabWineX Select</span>
								</span>
							)}
						</div>
						<div className="max-w-lg">
							<Paragraphs text={product.description} />
						</div>
						<table
							id="details"
							className="w-full max-w-lg mt-4 mb-6 border-gray-400 border-t border-b"
						>
							<tbody className="divide-y divide-gray-400">
								<tr>
									<td>
										<Icon name="Medal" size="sm" className="text-gray-700" />
									</td>
									<td className="pt-3 pb-2.5">Accolades</td>
									<td className="text-right pt-3 pb-2.5">
										{_.isEmpty(product.accolades)
											? na
											: _.map(product.accolades.split("\n"), (v, i) => (
													<span className="block" key={i}>
														{v}
													</span>
											  ))}
									</td>
								</tr>
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">AVA</td>
									<td className="text-right">
										<Link to={`/shop?ava=${product.avaSlug}`}>{product.avaName}</Link>
									</td>
								</tr>
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Alcohol</td>
									<td className="text-right">{alcohol}</td>
								</tr>
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Case&nbsp;format</td>
									<td className="text-right">
										<Link to={`/shop?format=${product.caseFormatSlug}`}>{product.caseFormat}</Link>
									</td>
								</tr>
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Certificates</td>
									<td className="text-right">
										{_.size(product.certifications) === 0
											? na
											: _.map(product.certifications, (v, i) => (
													<span key={i}>
														<Link to={`/shop?certifications=${v.slug}`}>{v.name}</Link>
														{i < _.size(product.certifications) - 1 ? ", " : null}
													</span>
											  ))}
									</td>
								</tr>
								<tr>
									<td>
										<Icon name="Crafted" size="sm" className="text-gray-700" />
									</td>
									<td className="pt-3 pb-2.5">Crafted</td>
									<td className="text-right">
										{_.size(product.crafted) === 0
											? na
											: _.map(product.crafted, (v, i) => (
													<span key={i}>
														<Link to={`/shop?crafted=${v.slug}`}>{v.name}</Link>
														{i < _.size(product.crafted) - 1 ? ", " : null}
													</span>
											  ))}
									</td>
								</tr>
								<tr>
									<td className="w-8">
										<Icon name="Notes" size="sm" className="text-gray-700" />
									</td>
									<td className="pt-3 pb-2.5">Notes</td>
									<td className="pt-3 pb-2.5 text-gray-800">
										{product.hasNotes ? (
											<div className="text-left text-sm">
												{product.notes ? (
													<div className="italic py-2 text-center">{`"${product.notes}"`}</div>
												) : null}
												{product.aroma ? (
													<div>
														<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
														{product.aroma}
													</div>
												) : null}
												{product.taste ? (
													<div>
														<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
														{product.taste}
													</div>
												) : null}
												{product.finish ? (
													<div>
														<Icon name="DotCircle" size="sm" fixedWidth className="mr-1" />
														{product.finish}
													</div>
												) : null}
											</div>
										) : (
											<span className="text-right block">{na}</span>
										)}
									</td>
								</tr>
								{loggedIn() ? (
									product.hidePrice ? (
										<tr>
											<td></td>
											<td className="pt-3 pb-2.5">Price</td>
											<td className="text-right">Inquire about pricing and availability</td>
										</tr>
									) : (
										<tr>
											<td></td>
											<td className="pt-3 pb-2.5">Price</td>
											<td className="text-right">
												<div className="divide-y divide-gray-400">
													<div className="py-2">
														<span className="text-gray-700 pr-2">Case</span>
														<span>
															<Link to={`/shop?price=${productPriceRange()}`}>{casePrice}</Link>
														</span>
													</div>
													<div className="py-2">
														<span className="text-gray-700 pr-2">Bottle</span>
														<span>{bottlePrice}</span>
													</div>
												</div>
											</td>
										</tr>
									)
								) : null}
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">
										{_.size(product.varietals) > 1 ? "Varietals" : "Varietal"}
									</td>
									<td className="text-right">
										{_.map(product.varietals, (v, i) => {
											const comma = i === _.size(product.varietals) - 1 ? "" : ", "
											return (
												<span key={i}>
													<Link to={`/shop?varietal=${v.slug}`}>{v.name}</Link>
													{comma}
												</span>
											)
										})}
									</td>
								</tr>
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Tasting notes</td>
									<td className="text-right">
										{_.isEmpty(product.tastingNotes) ? (
											na
										) : (
											<>
												<a
													className="hover:text-gray-700 hover:underline"
													target="_blank"
													rel="noreferrer"
													href={_.first(product.tastingNotes)}
												>
													View
													<Icon className="ml-1" name="TastingNotes" />
												</a>
											</>
										)}
									</td>
								</tr>

								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">U.S. Average Retail Price</td>
									<td className="text-right">
										<div className="divide-y divide-gray-400">
											<div className="py-2">
												<span className="text-gray-700 pr-2">Bottle</span>
												<span>{retailBottlePrice}</span>
											</div>
										</div>
									</td>
								</tr>

								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Vintage</td>
									<td className="text-right">
										<Link to={`/shop?vintage=${product.vintageSlug}`}>{product.vintage}</Link>
									</td>
								</tr>
								{_.isEmpty(product.website) ? null : (
									<tr>
										<td></td>
										<td className="pt-3 pb-2.5">Website</td>
										<td className="text-right">
											<a
												className="hover:text-blue-700 hover:underline"
												href={product.website}
												target="_blank"
												rel="noreferrer"
											>
												{product.website}
											</a>
										</td>
									</tr>
								)}
								<tr>
									<td></td>
									<td className="pt-3 pb-2.5">Winery</td>
									<td className="text-right">
										<Link to={`/shop?winery=${product.brandSlug}`}>{product.brandName}</Link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div id="gallery" className="bg-white max-w-xl lg:max-w-auto lg:w-1/2">
						<div className="flex justify-center border-gray-400 border lg:p-8">
							<img
								className="object-none object-center object-scale-down"
								style={{ maxHeight: "50vh", minHeight: "50vh" }}
								src={product.images[imageIndex]}
								alt="Bottle or label image"
							/>
						</div>
						<div className="flex flex-row mt-4 space-x-4">
							{_.size(product.thumbnails) > 1
								? _.map(product.thumbnails, (image, i) => {
										return (
											<div
												key={image}
												className={`cursor-pointer border p-2 ${
													i === imageIndex ? "border-gray-800" : "border-gray-400"
												}`}
												onClick={() => {
													setImageIndex(i)
												}}
											>
												<img
													className="object-none object-center object-scale-down max-h-20 w-20"
													src={image}
													alt="Bottle or label image"
												/>
											</div>
										)
								  })
								: null}
						</div>
					</div>
				</div>
			</section>
		</MainLayout>
	)
}
