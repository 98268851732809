/* eslint-disable react/no-unescaped-entities, react/jsx-no-target-blank */
import React from "react"

import { MainLayout } from "@app/layouts"

export const PrivacyPolicyPage = () => {
	return (
		<MainLayout>
			<div className="prismic mx-auto max-w-4xl px-4 py-6">
				<h1 className="text-xl">Privacy Policy</h1>
				<p className="text-sm">Last updated January 01, 2021</p>
				<p>
					Thank you for choosing to be part of our community at Collaboration Wine Exports LLC ("
					<strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>", "
					<strong>our</strong>"). We are committed to protecting your personal information and your
					right to privacy. If you have any questions or concerns about this privacy notice, or our
					practices with regards to your personal information, please contact us at
					privacy@collaboration.wine.
				</p>
				<p>
					When you visit our website{" "}
					<a href="https://collaboration.wine" target="_blank" data-custom-class="link">
						https://collaboration.wine
					</a>{" "}
					(the "<strong>Website</strong>"), and more generally, use any of our services (the "
					<strong>Services</strong>", which include the&nbsp;Website), we appreciate that you are
					trusting us with your personal information. We take your privacy very seriously. In this
					privacy notice, we seek to explain to you in the clearest way possible what information we
					collect, how we use it and what rights you have in relation to it. We hope you take some
					time to read through it carefully, as it is important. If there are any terms in this
					privacy notice that you do not agree with, please discontinue use of our Services
					immediately.
				</p>
				<p>
					This privacy notice applies to all information collected through our Services (which, as
					described above, includes our&nbsp;Website), as well as, any related services, sales,
					marketing or events.
				</p>
				<p>
					<strong>
						Please read this privacy notice carefully as it will help you understand what we do with
						the information that we collect.
					</strong>
				</p>
				<div>
					<strong>TABLE OF CONTENTS</strong>
				</div>
				<div>
					<a href="#infocollect" data-custom-class="link">
						1. WHAT INFORMATION DO WE COLLECT?
					</a>
				</div>
				<div>
					<a href="#infouse" data-custom-class="link">
						2. HOW DO WE USE YOUR INFORMATION?
					</a>
				</div>
				<div>
					<a href="#infoshare" data-custom-class="link">
						3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
					</a>
				</div>
				<div>
					<a href="#cookies" data-custom-class="link">
						4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
					</a>
				</div>
				<div>
					<a href="#intltransfers" data-custom-class="link">
						5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
					</a>
				</div>
				<div>
					<a href="#inforetain" data-custom-class="link">
						6. HOW LONG DO WE KEEP YOUR INFORMATION?
					</a>
				</div>
				<div>
					<a href="#infosafe" data-custom-class="link">
						7. HOW DO WE KEEP YOUR INFORMATION SAFE?
					</a>
				</div>
				<div>
					<a href="#infominors" data-custom-class="link">
						8. DO WE COLLECT INFORMATION FROM MINORS?
					</a>
				</div>
				<div>
					<a href="#privacyrights" data-custom-class="link">
						9. WHAT ARE YOUR PRIVACY RIGHTS?
					</a>
				</div>
				<div>
					<a href="#DNT" data-custom-class="link">
						10. CONTROLS FOR DO-NOT-TRACK FEATURES
					</a>
				</div>
				<div>
					<a href="#caresidents" data-custom-class="link">
						11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
					</a>
				</div>
				<div>
					<a href="#policyupdates" data-custom-class="link">
						12. DO WE MAKE UPDATES TO THIS NOTICE?
					</a>
				</div>
				<div>
					<a href="#contact" data-custom-class="link">
						13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
					</a>
				</div>
				<div>
					<a href="#request" data-custom-class="link">
						14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
					</a>
				</div>
				<div id="infocollect">
					<strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
				</div>
				<div>
					<strong>Personal information you disclose to us</strong>
				</div>
				<div>
					<div>
						<strong>
							<em>In Short:&nbsp;</em>
						</strong>
						<strong></strong>
						<em>We collect personal information that you provide to us.</em>
					</div>
				</div>
				<p>
					We collect personal information that you voluntarily provide to us when you register on
					the&nbsp;Website, express an interest in obtaining information about us or our products
					and Services, when you participate in activities on the Website&nbsp;or otherwise when you
					contact us.
				</p>
				<p>
					The personal information that we collect depends on the context of your interactions with
					us and the Website, the choices you make and the products and features you use. The
					personal information we collect may include the following:
				</p>
				<p>
					<strong>Personal Information Provided by You.</strong> We collect names;&nbsp;phone
					numbers;&nbsp;email addresses;&nbsp;mailing addresses;&nbsp;contact
					preferences;&nbsp;billing addresses;&nbsp;and other similar information.
				</p>
				<p>
					All personal information that you provide to us must be true, complete and accurate, and
					you must notify us of any changes to such personal information.
				</p>
				<div id="infouse">
					<strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
				</div>
				<div>
					<div>
						<strong>
							<em>In Short: </em>
						</strong>
						<em>
							We process your information for purposes based on legitimate business interests, the
							fulfillment of our contract with you, compliance with our legal obligations, and/or
							your consent.
						</em>
					</div>
				</div>
				<div>
					We use personal information collected via our Website for a variety of business purposes
					described below. We process your personal information for these purposes in reliance on
					our legitimate business interests, in order to enter into or perform a contract with you,
					with your consent, and/or for compliance with our legal obligations. We indicate the
					specific processing grounds we rely on next to each purpose listed below.
				</div>
				<div>We use the information we collect or receive:</div>
				<ul>
					<li>
						<strong>To facilitate account creation and logon process.</strong>
					</li>
				</ul>
				<ul>
					<li>
						<strong>To post testimonials.</strong> We post testimonials on our Website that may
						contain personal information. Prior to posting a testimonial, we will obtain your
						consent to use your name and the content of the testimonial. If you wish to update, or
						delete your testimonial, please contact us at privacy@collaboration.wine and be sure to
						include your name, testimonial location, and contact information.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Request feedback.&nbsp;</strong>We may use your information to request feedback
						and to contact you about your use of our Website.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To enable user-to-user communications.</strong> We may use your information in
						order to enable user-to-user communications with each user's consent.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To manage user accounts.&nbsp;</strong>We may use your information for the
						purposes of managing our account and keeping it in working order.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To send administrative information to you.&nbsp;</strong>We may use your
						personal information to send you product, service and new feature information and/or
						information about changes to our terms, conditions, and policies.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To protect our Services.&nbsp;</strong>We may use your information as part of
						our efforts to keep our Website safe and secure (for example, for fraud monitoring and
						prevention).
					</li>
				</ul>
				<ul>
					<li>
						<strong>
							To enforce our terms, conditions and policies for business purposes, to comply with
							legal and regulatory requirements or in connection with our contract.
						</strong>
					</li>
				</ul>
				<ul>
					<li>
						<strong>To respond to legal requests and prevent harm.&nbsp;</strong>If we receive a
						subpoena or other legal request, we may need to inspect the data we hold to determine
						how to respond.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Fulfill and manage your orders.&nbsp;</strong>We may use your information to
						fulfill and manage your orders, payments, returns, and exchanges made through the
						Website.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Administer prize draws and competitions.</strong> We may use your information to
						administer prize draws and competitions when you elect to participate in our
						competitions.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To deliver and facilitate delivery of services to the user.</strong> We may use
						your information to provide you with the requested service.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To respond to user inquiries/offer support to users.</strong> We may use your
						information to respond to your inquiries and solve any potential issues you might have
						with the use of our Services.
					</li>
				</ul>
				<ul>
					<li>
						<strong>To send you marketing and promotional communications.</strong> We and/or our
						third-party marketing partners may use the personal information you send to us for our
						marketing purposes, if this is in accordance with your marketing preferences. For
						example, when expressing an interest in obtaining information about us or our Website,
						subscribing to marketing or otherwise contacting us, we will collect personal
						information from you. You can opt-out of our marketing emails at any time (see the "
						<a href="#privacyrights" data-custom-class="link">
							WHAT ARE YOUR PRIVACY RIGHTS
						</a>
						" below).
					</li>
				</ul>
				<ul>
					<li>
						<strong>Deliver targeted advertising to you.</strong>&nbsp;We may use your information
						to develop and display personalized content and advertising (and work with third parties
						who do so) tailored to your interests and/or location and to measure its effectiveness.
					</li>
				</ul>
				<div>
					<div id="infoshare">
						<strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
					</div>
					<div>
						<strong>
							<em>In Short:</em>
						</strong>
						<em>
							{" "}
							We only share information with your consent, to comply with laws, to provide you with
							services, to protect your rights, or to fulfill business obligations.
						</em>
					</div>
					<div>
						We may process or share your data that we hold based on the following legal basis:
					</div>
					<ul>
						<li>
							<strong>Consent:</strong> We may process your data if you have given us specific
							consent to use your personal information for a specific purpose.
						</li>
					</ul>
					<ul>
						<li>
							<strong>Legitimate Interests:</strong> We may process your data when it is reasonably
							necessary to achieve our legitimate business interests.
						</li>
					</ul>
					<ul>
						<li>
							<strong>Performance of a Contract:</strong> Where we have entered into a contract with
							you, we may process your personal information to fulfill the terms of our contract.
						</li>
					</ul>
					<ul>
						<li>
							<strong>Legal Obligations:</strong> We may disclose your information where we are
							legally required to do so in order to comply with applicable law, governmental
							requests, a judicial proceeding, court order, or legal process, such as in response to
							a court order or a subpoena (including in response to public authorities to meet
							national security or law enforcement requirements).
						</li>
					</ul>
					<ul>
						<li>
							<strong>Vital Interests:</strong> We may disclose your information where we believe it
							is necessary to investigate, prevent, or take action regarding potential violations of
							our policies, suspected fraud, situations involving potential threats to the safety of
							any person and illegal activities, or as evidence in litigation in which we are
							involved.
						</li>
					</ul>
					<div>
						More specifically, we may need to process your data or share your personal information
						in the following situations:
					</div>
					<ul>
						<li>
							<strong>Business Transfers.</strong> We may share or transfer your information in
							connection with, or during negotiations of, any merger, sale of company assets,
							financing, or acquisition of all or a portion of our business to another company.
						</li>
					</ul>
					<ul>
						<li>
							<strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may
							share your data with third-party vendors, service providers, contractors or agents who
							perform services for us or on our behalf and require access to such information to do
							that work. Examples include: payment processing, data analysis, email delivery,
							hosting services, customer service and marketing efforts. We may allow selected third
							parties to use tracking technology on the Website, which will enable them to collect
							data on our behalf about how you interact with our Website over time. This information
							may be used to, among other things, analyze and track data, determine the popularity
							of certain content, pages or features, and better understand online activity. Unless
							described in this notice, we do not share, sell, rent or trade any of your information
							with third parties for their promotional purposes. We have contracts in place with our
							data processors, which are designed to help safeguard your personal information. This
							means that they cannot do anything with your personal information unless we have
							instructed them to do it. They will also not share your personal information with any
							organization apart from us. They also commit to protect the data they hold on our
							behalf and to retain it for the period we instruct.
						</li>
					</ul>
					<div>
						<div>
							<ul>
								<li>
									<strong>Business Partners.&nbsp;</strong>We may share your information with our
									business partners to offer you certain products, services or promotions.
								</li>
							</ul>
							<div>
								<div>
									<div id="cookies">
										<strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:&nbsp;</em>
										</strong>
										<em>
											&nbsp;We may use cookies and other tracking technologies to collect and store
											your information.
										</em>
									</div>
									<div>
										We may use cookies and similar tracking technologies (like web beacons and
										pixels) to access or store information. Specific information about how we use
										such technologies and how you can refuse certain cookies is set out in our
										Cookie Notice.
									</div>
									<div id="intltransfers">
										<strong>5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</div>
									<div>
										<strong>
											<em>In Short: </em>
										</strong>
										<em>
											We may transfer, store, and process your information in countries other than
											your own.
										</em>
									</div>
									<div>
										Our servers are located in United States. If you are accessing our Website from
										outside United States, please be aware that your information may be transferred
										to, stored, and processed by us in our facilities and by those third parties
										with whom we may share your personal information (see "
										<a href="#infoshare" data-custom-class="link">
											WILL YOUR INFORMATION BE SHARED WITH ANYONE?
										</a>
										" above), in United States, and other countries.
									</div>
									<div>
										If you are a resident in the European Economic Area, then these countries may
										not necessarily have data protection laws or other similar laws as comprehensive
										as those in your country. We will however take all necessary measures to protect
										your personal information in accordance with this privacy notice and applicable
										law.
									</div>
									<div>European Commission's Standard Contractual Clauses:</div>
									<div>
										We have implemented measures to protect your personal information, including by
										using the European Commission's Standard Contractual Clauses for transfers of
										personal information between our group companies and between us and our
										third-party providers. These clauses require all recipients to protect all
										personal information that they process originating from the EEA in accordance
										with European data protection laws and regulations. Our Standard Contractual
										Clauses can be provided upon request. We have implemented similar appropriate
										safeguards with our third-party service providers and partners and further
										details can be provided upon request.
									</div>
									<div id="inforetain">
										<strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:&nbsp;</em>
										</strong>
										<em>
											&nbsp;We keep your information for as long as necessary to fulfill the
											purposes outlined in this privacy notice unless otherwise required by law.
										</em>
									</div>
									<div>
										We will only keep your personal information for as long as it is necessary for
										the purposes set out in this privacy notice, unless a longer retention period is
										required or permitted by law (such as tax, accounting or other legal
										requirements). No purpose in this notice will require us keeping your personal
										information for longer than the period of time in which users have an account
										with us.
									</div>
									<div>
										When we have no ongoing legitimate business need to process your personal
										information, we will either delete or anonymize such information, or, if this is
										not possible (for example, because your personal information has been stored in
										backup archives), then we will securely store your personal information and
										isolate it from any further processing until deletion is possible.
									</div>
									<div id="infosafe">
										<strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:&nbsp;</em>
										</strong>
										<em>
											&nbsp;We aim to protect your personal information through a system of
											organizational and technical security measures.
										</em>
									</div>
									<div>
										We have implemented appropriate technical and organizational security measures
										designed to protect the security of any personal information we process.
										However, despite our safeguards and efforts to secure your information, no
										electronic transmission over the Internet or information storage technology can
										be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
										cybercriminals, or other unauthorized third parties will not be able to defeat
										our security, and improperly collect, access, steal, or modify your information.
										Although we will do our best to protect your personal information, transmission
										of personal information to and from our Website is at your own risk. You should
										only access the Website within a secure environment.
									</div>
									<div id="infominors">
										<strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:</em>
										</strong>
										<em>
											{" "}
											We do not knowingly collect data from or market to children under 18 years of
											age.
										</em>
									</div>
									<div>
										We do not knowingly solicit data from or market to children under 18 years of
										age. By using the Website, you represent that you are at least 18 or that you
										are the parent or guardian of such a minor and consent to such minor
										dependent&rsquo;s use of the Website. If we learn that personal information from
										users less than 18 years of age has been collected, we will deactivate the
										account and take reasonable measures to promptly delete such data from our
										records. If you become aware of any data we may have collected from children
										under age 18, please contact us at privacy@collaboration.wine.
									</div>
									<div id="privacyrights">
										<strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:</em>
										</strong>
										<em>
											{" "}
											In some regions, such as the European Economic Area, you have rights that
											allow you greater access to and control over your personal information. You
											may review, change, or terminate your account at any time.
										</em>
									</div>
									<div>
										In some regions (like the European Economic Area), you have certain rights under
										applicable data protection laws. These may include the right (i) to request
										access and obtain a copy of your personal information, (ii) to request
										rectification or erasure; (iii) to restrict the processing of your personal
										information; and (iv) if applicable, to data portability. In certain
										circumstances, you may also have the right to object to the processing of your
										personal information. To make such a request, please use the{" "}
										<a href="#contact" data-custom-class="link">
											contact details
										</a>{" "}
										provided below. We will consider and act upon any request in accordance with
										applicable data protection laws.
									</div>
									<div>
										If we are relying on your consent to process your personal information, you have
										the right to withdraw your consent at any time. Please note however that this
										will not affect the lawfulness of the processing before its withdrawal, nor will
										it affect the processing of your personal information conducted in reliance on
										lawful processing grounds other than consent.
									</div>
									<div>
										If you are a resident in the European Economic Area and you believe we are
										unlawfully processing your personal information, you also have the right to
										complain to your local data protection supervisory authority. You can find their
										contact details here:{" "}
										<a
											href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
											target="_blank"
											rel="noopener noreferrer"
											data-custom-class="link"
										>
											http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
										</a>
										.
									</div>
									<div>
										If you are a resident in Switzerland, the contact details for the data
										protection authorities are available here:{" "}
										<a
											href="https://www.edoeb.admin.ch/edoeb/en/home.html"
											target="_blank"
											rel="noopener noreferrer"
											data-custom-class="link"
										>
											https://www.edoeb.admin.ch/edoeb/en/home.html
										</a>
										.
									</div>
									<div>
										If you have questions or comments about your privacy rights, you may email us at
										privacy@collaboration.wine.
									</div>
									<div>
										<strong>
											<u>
												<br />
											</u>
										</strong>
										<strong>Account Information</strong>
									</div>
									<div>
										<div>
											If you would at any time like to review or change the information in your
											account or terminate your account, you can:
										</div>
										<ul>
											<li>Contact us using the contact information provided.</li>
										</ul>
										<div>
											Upon your request to terminate your account, we will deactivate or delete your
											account and information from our active databases. However, we may retain some
											information in our files to prevent fraud, troubleshoot problems, assist with
											any investigations, enforce our Terms of Use and/or comply with applicable
											legal requirements.
										</div>
									</div>
									<div>
										<strong>
											<u>Cookies and similar technologies:</u>
										</strong>{" "}
										Most Web browsers are set to accept cookies by default. If you prefer, you can
										usually choose to set your browser to remove cookies and to reject cookies. If
										you choose to remove cookies or reject cookies, this could affect certain
										features or services of our Website. To opt-out of interest-based advertising by
										advertisers on our Website visit{" "}
										<a
											href="http://www.aboutads.info/choices/"
											target="_blank"
											rel="noopener noreferrer"
											data-custom-class="link"
										>
											http://www.aboutads.info/choices/
										</a>
										.{" "}
									</div>
									<div>
										<strong>
											<u>Opting out of email marketing:</u>
										</strong>{" "}
										You can unsubscribe from our marketing email list at any time by clicking on the
										unsubscribe link in the emails that we send or by contacting us using the
										details provided below. You will then be removed from the marketing email list
										&mdash; however, we may still communicate with you, for example to send you
										service-related emails that are necessary for the administration and use of your
										account, to respond to service requests, or for other non-marketing purposes. To
										otherwise opt-out, you may:
									</div>
									<ul>
										<li>Contact us using the contact information provided.</li>
									</ul>
									<div id="DNT">
										<strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
									</div>
									<div>
										Most web browsers and some mobile operating systems and mobile applications
										include a Do-Not-Track ("DNT") feature or setting you can activate to signal
										your privacy preference not to have data about your online browsing activities
										monitored and collected. At this stage no uniform technology standard for
										recognizing and implementing DNT signals has been finalized. As such, we do not
										currently respond to DNT browser signals or any other mechanism that
										automatically communicates your choice not to be tracked online. If a standard
										for online tracking is adopted that we must follow in the future, we will inform
										you about that practice in a revised version of this privacy notice.&nbsp;
									</div>
									<div id="caresidents">
										<strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
									</div>
									<div>
										<strong>
											<em>In Short:&nbsp;</em>
										</strong>
										<em>
											&nbsp;Yes, if you are a resident of California, you are granted specific
											rights regarding access to your personal information.
										</em>
									</div>
									<div>
										California Civil Code Section 1798.83, also known as the "Shine The Light" law,
										permits our users who are California residents to request and obtain from us,
										once a year and free of charge, information about categories of personal
										information (if any) we disclosed to third parties for direct marketing purposes
										and the names and addresses of all third parties with which we shared personal
										information in the immediately preceding calendar year. If you are a California
										resident and would like to make such a request, please submit your request in
										writing to us using the contact information provided below.
									</div>
									<div>
										If you are under 18 years of age, reside in California, and have a registered
										account with the Website, you have the right to request removal of unwanted data
										that you publicly post on the Website. To request removal of such data, please
										contact us using the contact information provided below, and include the email
										address associated with your account and a statement that you reside in
										California. We will make sure the data is not publicly displayed on the Website,
										but please be aware that the data may not be completely or comprehensively
										removed from all our systems (e.g. backups, etc.).
									</div>
									<div>
										<strong>
											<u>
												<br />
											</u>
										</strong>
										<strong>CCPA Privacy Notice</strong>
									</div>
									<div>
										<div>The California Code of Regulations defines a "resident" as:</div>
									</div>
									<div>
										(1) every individual who is in the State of California for other than a
										temporary or transitory purpose and
									</div>
									<div>
										(2) every individual who is domiciled in the State of California who is outside
										the State of California for a temporary or transitory purpose
									</div>
									<div>All other individuals are defined as "non-residents."</div>
									<div>
										If this definition of "resident" applies to you, we must adhere to certain
										rights and obligations regarding your personal information.
									</div>
									<div>
										<strong>What categories of personal information do we collect?</strong>
									</div>
									<div>
										We have collected the following categories of personal information in the past
										twelve (12) months:
									</div>
									<table>
										<tbody>
											<tr>
												<td>
													<br />
													<strong>Category</strong>
													<br />
													<br />
												</td>
												<td>
													<br />
													<strong>Examples</strong>
													<br />
													<br />
												</td>
												<td>
													<br />
													<strong>Collected</strong>
													<br />
													<br />
												</td>
											</tr>
											<tr>
												<td>
													<div>A. Identifiers</div>
												</td>
												<td>
													<div>
														Contact details, such as real name, alias, postal address, telephone or
														mobile contact number, unique personal identifier, online identifier,
														Internet Protocol address, email address and account name
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>
														B. Personal information categories listed in the California Customer
														Records statute
													</div>
												</td>
												<td>
													<div>
														Name, contact information, education, employment, employment history and
														financial information
													</div>
												</td>
												<td>
													<div>YES</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>
														C. Protected classification characteristics under California or federal
														law
													</div>
												</td>
												<td>
													<div>Gender and date of birth</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>D. Commercial information</div>
												</td>
												<td>
													<div>
														Transaction information, purchase history, financial details and payment
														information
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">YES</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>E. Biometric information</div>
												</td>
												<td>
													<div>Fingerprints and voiceprints</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>F. Internet or other similar network activity</div>
												</td>
												<td>
													<div>
														Browsing history, search history, online behavior, interest data, and
														interactions with our and other websites, applications, systems and
														advertisements
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">YES</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>G. Geolocation data</div>
												</td>
												<td>
													<div>Device location</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>
														H. Audio, electronic, visual, thermal, olfactory, or similar information
													</div>
												</td>
												<td>
													<div>
														Images and audio, video or call recordings created in connection with
														our business activities
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>I. Professional or employment-related information</div>
												</td>
												<td>
													<div>
														Business contact details in order to provide you our services at a
														business level, job title as well as work history and professional
														qualifications if you apply for a job with us
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>J. Education Information</div>
												</td>
												<td>
													<div>Student records and directory information</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
											<tr>
												<td>
													<div>K. Inferences drawn from other personal information</div>
												</td>
												<td>
													<div>
														Inferences drawn from any of the collected personal information listed
														above to create a profile or summary about, for example, an
														individual&rsquo;s preferences and characteristics
													</div>
												</td>
												<td>
													<div data-custom-class="body_text">NO</div>
												</td>
											</tr>
										</tbody>
									</table>
									<div>
										We may also collect other personal information outside of these categories
										instances where you interact with us in-person, online, or by phone or mail in
										the context of:
									</div>
									<ul>
										<li>Receiving help through our customer support channels;</li>
									</ul>
									<ul>
										<li>Participation in customer surveys or contests; and</li>
									</ul>
									<ul>
										<li>
											Facilitation in the delivery of our Services and to respond to your inquiries.
										</li>
									</ul>
									<div>
										<strong>How do we use and share your personal information?</strong>
									</div>
									<div>
										<div>
											<div>
												More information about our data collection and sharing practices can be
												found in this privacy notice.
											</div>
											<div>
												You may contact us by email at&nbsp;privacy@collaboration.wine, or by
												referring to the contact details at the bottom of this document.
											</div>
											<div>
												If you are using an authorized agent to exercise your right to opt-out we
												may deny a request if the authorized agent does not submit proof that they
												have been validly authorized to act on your behalf.
											</div>
											<div>
												<strong>Will your information be shared with anyone else?</strong>
											</div>
											<div>
												We may disclose your personal information with our service providers
												pursuant to a written contract between us and each service provider. Each
												service provider is a for-profit entity that processes the information on
												our behalf.
											</div>
											<div>The current list of our service providers can be found in below.</div>
											<div>
												We may use your personal information for our own business purposes, such as
												for undertaking internal research for technological development and
												demonstration. This is not considered to be "selling" of your personal data.
											</div>
											<div>
												Collaboration Wine Exports LLC&nbsp;has disclosed the following categories
												of personal information to third parties for a business or commercial
												purpose in the preceding twelve (12) months:
												<ul>
													<li>
														Category B. Personal information, as defined in the California Customer
														Records law, such as your name, contact information, education,
														employment, employment history and financial information.
													</li>
												</ul>
												<ul>
													<li>
														Category D. Commercial information, such as transaction information,
														purchase history, financial details and payment information.
													</li>
												</ul>
												<ul>
													<li>
														Category F. Internet or other electronic network activity information,
														such as browsing history, search history, online behavior, interest
														data, and interactions with our and other websites, applications,
														systems and advertisements.
													</li>
												</ul>
												<div>
													<div>
														<div>
															<div>
																<div>
																	<div>
																		The categories of third parties to whom we disclosed personal
																		information for a business or commercial purpose can be found
																		under "
																		<a href="#whoshare" data-custom-class="link">
																			WHO WILL YOUR INFORMATION BE SHARED WITH?
																		</a>
																		".
																	</div>
																	<div>
																		<strong>Your rights with respect to your personal data</strong>
																	</div>
																	<div>
																		<u>Right to request deletion of the data - Request to delete</u>
																	</div>
																	<div>
																		You can ask for the deletion of your personal information. If
																		you ask us to delete your personal information, we will respect
																		your request and delete your personal information, subject to
																		certain exceptions provided by law, such as (but not limited to)
																		the exercise by another consumer of his or her right to free
																		speech, our compliance requirements resulting from a legal
																		obligation or any processing that may be required to protect
																		against illegal activities.
																	</div>
																	<div>
																		<u>Right to be informed - Request to know</u>
																	</div>
																	<div>
																		Depending on the circumstances, you have a right to know:
																	</div>
																	<ul>
																		<li>whether we collect and use your personal information;</li>
																	</ul>
																	<ul>
																		<li>the categories of personal information that we collect;</li>
																	</ul>
																	<ul>
																		<li>
																			the purposes for which the collected personal information is
																			used;
																		</li>
																	</ul>
																	<ul>
																		<li>
																			whether we sell your personal information to third parties;
																		</li>
																	</ul>
																	<ul>
																		<li>
																			the categories of personal information that we sold or
																			disclosed for a business purpose;
																		</li>
																	</ul>
																	<ul>
																		<li>
																			the categories of third parties to whom the personal
																			information was sold or disclosed for a business purpose; and
																		</li>
																	</ul>
																	<ul>
																		<li>
																			the business or commercial purpose for collecting or selling
																			personal information.
																		</li>
																	</ul>
																	<div>
																		In accordance with applicable law, we are not obligated to
																		provide or delete consumer information that is de-identified in
																		response to a consumer request or to re-identify individual data
																		to verify a consumer request.
																	</div>
																	<div>
																		<u>
																			Right to Non-Discrimination for the Exercise of a
																			Consumer&rsquo;s Privacy Rights
																		</u>
																	</div>
																	<div>
																		We will not discriminate against you if you exercise your
																		privacy rights.
																	</div>
																	<div>
																		<u>Verification process</u>
																	</div>
																	<div>
																		Upon receiving your request, we will need to verify your
																		identity to determine you are the same person about whom we have
																		the information in our system. These verification efforts
																		require us to ask you to provide information so that we can
																		match it with information you have previously provided us. For
																		instance, depending on the type of request you submit, we may
																		ask you to provide certain information so that we can match the
																		information you provide with the information we already have on
																		file, or we may contact you through a communication method (e.g.
																		phone or email) that you have previously provided to us. We may
																		also use other verification methods as the circumstances
																		dictate.
																	</div>
																	<div>
																		We will only use personal information provided in your request
																		to verify your identity or authority to make the request. To the
																		extent possible, we will avoid requesting additional information
																		from you for the purposes of verification. If, however, if we
																		cannot verify your identity from the information already
																		maintained by us, we may request that you provide additional
																		information for the purposes of verifying your identity, and for
																		security or fraud-prevention purposes. We will delete such
																		additionally provided information as soon as we finish verifying
																		you.
																	</div>
																	<div>
																		<u>Other privacy rights</u>
																	</div>
																	<ul>
																		<li>you may object to the processing of your personal data</li>
																	</ul>
																	<ul>
																		<li>
																			you may request correction of your personal data if it is
																			incorrect or no longer relevant, or ask to restrict the
																			processing of the data
																		</li>
																	</ul>
																	<ul>
																		<li>
																			you can designate an authorized agent to make a request under
																			the CCPA on your behalf. We may deny a request from an
																			authorized agent that does not submit proof that they have
																			been validly authorized to act on your behalf in accordance
																			with the CCPA.
																		</li>
																	</ul>
																	<ul>
																		<li>
																			you may request to opt-out from future selling of your
																			personal information to third parties. Upon receiving a
																			request to opt-out, we will act upon the request as soon as
																			feasibly possible, but no later than 15 days from the date of
																			the request submission.
																		</li>
																	</ul>
																	<div>
																		To exercise these rights, you can contact us&nbsp;by email at
																		privacy@collaboration.wine, or by referring to the contact
																		details at the bottom of this document. If you have a complaint
																		about how we handle your data, we would like to hear from you.
																	</div>
																	<div id="policyupdates">
																		<strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	</div>
																	<div></div>
																	<div>
																		<em>
																			<strong>In Short:&nbsp;</strong> Yes, we will update this
																			notice as necessary to stay compliant with relevant laws.
																		</em>
																	</div>
																	<div>
																		We may update this privacy notice from time to time. The updated
																		version will be indicated by an updated "Revised" date and the
																		updated version will be effective as soon as it is accessible.
																		If we make material changes to this privacy notice, we may
																		notify you either by prominently posting a notice of such
																		changes or by directly sending you a notification. We encourage
																		you to review this privacy notice frequently to be informed of
																		how we are protecting your information.
																	</div>
																	<div id="contact">
																		<strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	</div>
																	<div>
																		If you have questions or comments about this notice, you may
																		email us at privacy@collaboration.wine or by post to:
																	</div>
																	<div>Collaboration Wine Exports LLC</div>
																	<div>3723 Mosswood Dr</div>
																	<div>Lafayette, CA 94549</div>
																	<div>United States</div>
																	<div id="request">
																		<strong>
																			14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
																			FROM YOU?
																		</strong>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	</div>
																	<p>
																		Based on the applicable laws of your country, you may have the
																		right to request access to the personal information we collect
																		from you, change that information, or delete it in some
																		circumstances. To request to review, update, or delete your
																		personal information, please visit: privacy@collaboration.wine.
																		We will respond to your request within 30 days.
																	</p>
																</div>
																<p>
																	This privacy policy was created using{" "}
																	<a href="https://termly.io/products/privacy-policy-generator/?ftseo">
																		Termly&rsquo;s Privacy Policy Generator
																	</a>
																	.
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}
