import React from "react"
import ReactSlider from "react-slider"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { RichText } from "prismic-reactjs"

import queryString from "query-string"

import { MainLayout } from "@app/layouts"
import { LoginBanner, Paragraphs, ProductCard, Link, Icon } from "@app/components"
import { useGlobalState } from "@app/state"
import { usePrismic, useSearch } from "@app/hooks"
import { useQuery } from "@app/router"
import { useSession } from "@app/contexts"

export const ProductListPage = () => {
	const { showShopWelcome, setShowShopWelcome, loggedIn } = useSession()
	const history = useHistory()
	const query = useQuery()
	const { snippets } = usePrismic()
	const { searching, searchResults, setSearchQuery, searchQuery } = useSearch(query["q"])

	const [mounted, setMounted] = React.useState(false)
	const [reRenderSlider, setReRenderSlider] = React.useState(false)
	const searchRef = React.useRef(null)

	const [avas] = useGlobalState("avas")
	const [brands] = useGlobalState("brands")
	const [filters] = useGlobalState("filters")
	const [priceRange] = useGlobalState("priceRange")
	let [products] = useGlobalState("products")
	products = _.filter(products, (p) => p.shopEnabled)
	const loading = _.isEmpty(products)

	React.useEffect(() => {
		if (reRenderSlider) {
			_.defer(() => {
				setReRenderSlider(false)
			})
		}
	}, [reRenderSlider])

	React.useEffect(() => {
		if (!mounted) {
			setMounted(true)
			return
		}
		const search = {
			...query,
			q: searchQuery.replaceAll(/\s+/g, "+"),
		}
		if (_.isEmpty(searchQuery)) {
			delete search["q"]
		}
		const next = `/shop?${queryString.stringify(search).replaceAll("%2B", "+")}`
		history.replace(next)
	}, [searchQuery])

	const [open, setOpen] = React.useState(() =>
		_.reduce(
			filters,
			(result, f) => {
				result[f.key] = _.has(query, f.key)
				return result
			},
			{},
		),
	)

	const defaultPriceRange = {
		min: priceRange.min,
		max: priceRange.max,
	}
	let filtering = false
	let filterDescriptions = []
	let brandDescription
	const groupChecked = {}

	const highlights = {}
	if (searching) {
		filterDescriptions.push({ name: "Search", value: query.q, key: "q", slug: "q" })
		products = _.filter(products, (product) => {
			const index = _.findIndex(searchResults, { id: product.id })
			if (index >= 0) {
				highlights[product.id] = searchResults[index].highlights
				return true
			}
			return false
		})
	}

	_.each(filters, (filter, i) => {
		const { key, slug } = filter
		filters[i].selected = _.size(query[key])
		if (_.isEmpty(query[key])) {
			return
		}
		_.each(filter.groups, (options, group) => {
			const groupKey = filter.key + group
			groupChecked[groupKey] = 0
			_.each(query[key], (slug) => {
				let match = false
				_.each(options, (o) => {
					if (o.slug === slug) {
						if (!filter.range) {
							filterDescriptions.push({ name: filter.name, value: o.name, key, slug })
						}
						match = true
						return false
					}
				})
				if (match) {
					groupChecked[groupKey]++
				}
			})
			groupChecked[groupKey] = _.size(options) == groupChecked[groupKey]
		})
		filtering = true
		let min, max
		if (filter.range) {
			const parts = _.get(query, key, "").toString().split("+")
			let parsed = true
			if (_.size(parts) === 2) {
				min = parseInt(parts[0], 10)
				max = parseInt(parts[1], 10)
				if (_.isNumber(min) && _.isNumber(max)) {
					defaultPriceRange.min = min
					defaultPriceRange.max = max
					filterDescriptions.push({
						name: "Case price",
						value: `$ ${min.toLocaleString()} — $ ${max.toLocaleString()}`,
						key,
						slug,
					})
				} else {
					parsed = false
				}
			} else {
				parsed = false
			}
			if (!parsed) {
				delete query[key]
			}
		}
		products = _.filter(products, (product) => {
			if (filter.range && _.isNumber(min) && _.isNumber(max)) {
				return product.price >= min && product.price <= max
			}
			return _.some(query[key], (v) => {
				if (_.isArray(product[slug])) {
					return _.find(product[slug], (item) => item.slug === v)
				}
				return product[slug] === v
			})
		})
	})

	if (!_.isEmpty(query["price"])) {
		products = _.sortBy(products, "price")
	}

	if (_.size(query["winery"]) === 1) {
		const slug = _.first(query["winery"])
		let brand
		_.each(brands.options, (b) => {
			if (b.slug === slug) {
				brand = b
				return false
			}
		})
		if (_.trim(_.get(brand, "description", "")) !== "") {
			brandDescription = (
				<div className="mb-8 mx-8">
					<div className="max-w-xl mx-auto p-4 bg-gray-300 rounded-lg">
						<h1 className="text-2xl mb-2">{brand.name}</h1>
						<Paragraphs text={brand.description} />
					</div>
				</div>
			)
		}
	}

	if (_.size(query["ava"]) === 1 && !brandDescription) {
		const slug = _.first(query["ava"])
		let ava
		_.each(avas.options, (a) => {
			if (a.slug === slug) {
				ava = a
				return false
			}
		})
		if (_.trim(_.get(ava, "description", "")) !== "") {
			brandDescription = (
				<div className="mb-8 mx-8">
					<div className="max-w-xl mx-auto p-4 bg-gray-300 rounded-lg">
						<h1 className="text-2xl mb-2">{ava.name}</h1>
						<Paragraphs text={ava.description} />
					</div>
				</div>
			)
		}
	}

	const FilterGroup = ({ group, filter, options, checked }) => {
		const className = checked ? "bg-gray-300" : ""
		return group ? (
			<div className="border-b pt-1 border-gray-300 mb-2">
				<div
					className={`tracking-wide text-lg flex flex-row justify-around group px-4 leading-6 cursor-pointer rounded-full ${className} hover:bg-gray-400 `}
					onClick={() => {
						const search = { ...query }
						search[filter.key] = []
						if (!checked) {
							_.each(options, (option) => {
								search[filter.key].push(option.slug)
							})
						}
						const next = `/shop?${queryString.stringify(search).replaceAll("%2B", "+")}`
						history.replace(next)
					}}
				>
					{checked ? (
						<Icon className="flex-initial text-black-400 mt-1" name="Check" size="xs" fixedWidth />
					) : (
						<Icon className="flex-initial text-gray-300 mt-1" name="Square" size="xs" fixedWidth />
					)}
					<div className="ml-2 flex-grow">{group}</div>
				</div>
			</div>
		) : null
	}

	const Filter = ({ checked, option, filter }) => {
		const className = checked ? "bg-gray-300" : ""
		return (
			<div
				className={`flex flex-row justify-around group px-4 leading-6 cursor-pointer rounded-full ${className} hover:bg-gray-400 `}
				onClick={() => {
					const search = { ...query }
					if (checked) {
						search[filter.key] = _.filter(search[filter.key], (x) => {
							return option.slug !== x
						})
					} else {
						if (search[filter.key] && !filter.mutuallyExclusive) {
							search[filter.key].push(option.slug)
						} else {
							search[filter.key] = [option.slug]
						}
					}
					if (filter.key === "price") {
						setReRenderSlider(true)
					}
					const next = `/shop?${queryString.stringify(search).replaceAll("%2B", "+")}`
					history.replace(next)
				}}
			>
				{checked ? (
					<Icon className="flex-initial text-black-400 mt-1" name="Check" size="xs" fixedWidth />
				) : (
					<Icon className="flex-initial text-gray-300 mt-1" name="Square" size="xs" fixedWidth />
				)}
				<div className="ml-2 flex-grow">{option.name}</div>
				{/* <div className="flex-initial pr-2 text-gray-700">{option.count}</div> */}
			</div>
		)
	}

	const priceRangeHandler = _.debounce((vals) => {
		const search = { ...query }
		search["price"] = `${vals[0]}+${vals[1]}`
		const next = `/shop?${queryString.stringify(search).replaceAll("%2B", "+")}`
		history.replace(next)
	}, 500)

	const clearSearch = () => {
		if (searchRef) {
			searchRef.current.value = ""
		}
		setSearchQuery("")
	}

	const shopWelcomeMessage = _.get(snippets, "shopWelcome")

	return (
		<MainLayout page="shop" loading={loading}>
			{!_.isEmpty(shopWelcomeMessage) && showShopWelcome && loggedIn() ? (
				<div className="bg-gray-300 mt-4 py-3">
					<div className="rounded text-white bg-gray-700 flex container py-4 px-8 mx-auto text-white max-w-xl space-x-4">
						<div className="w-4">
							<button
								type="button"
								className="focus:outline-none"
								onClick={() => setShowShopWelcome(false)}
							>
								<Icon className="" name="Times" />
							</button>
						</div>
						<div className="prismic-inverse">
							<RichText render={shopWelcomeMessage} />
						</div>
					</div>
				</div>
			) : null}
			<LoginBanner />
			<div className="flex mx-auto py-6">
				<div className="hidden sticky top-0 md:block min-w-[240px] h-screen overflow-y-scroll overflow-x-hidden ml-2">
					<div className="w-full mb-4">
						{_.map(filters, (filter) => {
							if (filter.hidden) {
								return null
							}
							return (
								<div key={filter.key}>
									<div
										className="flex flex-row justify-items rounded-full px-4 pt-1 pb-0.5 hover:bg-gray-400"
										onClick={() =>
											setOpen((prev) => _.set({ ...prev }, filter.key, !prev[filter.key]))
										}
									>
										<span className="flex-1 text-xl cursor-pointer">{filter.name}</span>
										{!open[filter.key] ? (
											filter.selected === 0 ? (
												<span className="flex-initial inline-flex items-center justify-center h-7 w-7">
													<Icon name="ChevronDown" />
												</span>
											) : (
												<span className="pt-0.5 inline-flex items-center justify-center h-7 w-7 font-bold text-white bg-black rounded-full">
													{filter.selected}
												</span>
											)
										) : (
											<span className="flex-initial inline-flex items-center justify-center h-7 w-7">
												<Icon name="ChevronUp" />
											</span>
										)}
									</div>
									<div className={`mb-2 ${open[filter.key] ? "" : "hidden"}`}>
										{!_.isEmpty(filter.range) ? (
											<div className="mx-4">
												{priceRange.min === priceRange.max ? null : (
													<div className="mt-1 mb-4">
														{reRenderSlider ? (
															<ReactSlider
																ariaLabel={["Min price", "Max price"]}
																ariaValuetext={(state) => `Price ${state.valueNow}`}
																className="slider-horizontal"
																value={[defaultPriceRange.min, defaultPriceRange.max]}
																markClassName="slider-mark"
																max={priceRange.max}
																min={priceRange.min}
																minDistance={10}
																pearling
																step={10}
																thumbClassName="slider-thumb"
																trackClassName="slider-track"
															/>
														) : (
															<ReactSlider
																ariaLabel={["Min price", "Max price"]}
																ariaValuetext={(state) => `Price ${state.valueNow}`}
																className="slider-horizontal"
																defaultValue={[defaultPriceRange.min, defaultPriceRange.max]}
																markClassName="slider-mark"
																max={priceRange.max}
																min={priceRange.min}
																minDistance={10}
																pearling
																onChange={priceRangeHandler}
																renderThumb={(props, state) => (
																	<div {...props}>
																		<Icon
																			name="ChevronLeft"
																			size="xs"
																			className="text-gray-500 mr-0.5"
																		/>
																		{`$ ${state.valueNow}`}
																		<Icon
																			name="ChevronRight"
																			size="xs"
																			className="text-gray-500 ml-0.5"
																		/>
																	</div>
																)}
																step={10}
																thumbClassName="slider-thumb"
																trackClassName="slider-track"
															/>
														)}
													</div>
												)}
											</div>
										) : null}
										{_.map(_.keys(filter.groups).sort(), (group) => {
											const options = filter.groups[group]
											return (
												<div key={group}>
													<FilterGroup
														filter={filter}
														group={group}
														options={options}
														checked={groupChecked[filter.key + group]}
													/>
													{_.map(
														_.filter(options, (o) => o.shopEnabled),
														(option) => (
															<Filter
																key={option.slug}
																option={option}
																filter={filter}
																checked={_.includes(_.get(query, filter.key), option.slug)}
															/>
														),
													)}
												</div>
											)
										})}
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="w-full flex-grow">
					<div className="bg-white leading-10 mb-4 mx-8">
						<div className="flex justify-around items-center">
							<div className="w-full max-w-sm relative">
								<Icon name="Search" className="absolute left-3 top-3 z-10 text-gray-400" />
								{_.isEmpty(searchQuery) ? null : (
									<Icon
										name="Times"
										className="absolute right-3 top-3 z-10 text-gray-700"
										onClick={() => {
											clearSearch()
										}}
									/>
								)}
								<input
									ref={searchRef}
									type="text"
									defaultValue={query.q}
									autoComplete="off"
									className="rounded-full leading-none border border-gray-400 px-9 pt-2 pb-1 w-full placeholder-gray-500 focus:outline-none"
									name="search"
									placeholder="Search..."
									onChange={(evt) => {
										const searchQuery = _.trim(_.get(evt, "target.value", ""))
										setSearchQuery(searchQuery)
									}}
								/>
							</div>
						</div>
						<div className="mt-2">
							{filtering || searching ? (
								<span className="text-sm mr-1">{`Showing ${_.size(products)} ${
									_.size(products === 1) ? "wine" : "wines"
								} filtered by:`}</span>
							) : null}
							{_.map(filterDescriptions, (fd, i) => (
								<span
									key={`${fd.key}${i}`}
									className="inline-block cursor-pointer group text-sm bg-gray-300 px-4 pt-3 pb-2 mb-1 rounded-full mr-2"
									onClick={() => {
										const search = { ...query }
										if (fd.key === "price") {
											delete search[fd.key]
										} else if (fd.key === "q") {
											delete search[fd.key]
											clearSearch()
										} else {
											search[fd.key] = _.filter(search[fd.key], (x) => {
												return fd.slug !== x
											})
										}
										const next = `/shop?${queryString.stringify(search).replaceAll("%2B", "+")}`
										history.replace(next)
									}}
								>
									<span className="text-gray-700">{`${fd.name}: `}</span>
									<span className="text-black">{fd.value}</span>
									<Icon
										className="text-gray-700 ml-2 group-hover:text-black"
										name="Times"
										size="xs"
									/>
								</span>
							))}
							{filtering || searching ? (
								<Link
									className="text-sm underline"
									to="/shop"
									onClick={() => {
										clearSearch()
									}}
								>
									Clear all
								</Link>
							) : null}
						</div>
					</div>
					{brandDescription ? brandDescription : null}
					<div className="flex flex-wrap flex-grow">
						{_.map(products, (v) => (
							<div key={v.id} className="xl:w-1/4 lg:w-1/3 md:w-1/2 p-4 w-full">
								<ProductCard product={v} highlights={highlights[v.id]} />
							</div>
						))}
					</div>
				</div>
			</div>
		</MainLayout>
	)
}
