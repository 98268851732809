import React from "react"

import { Link, Icon } from "@app/components"
import { useSession } from "@app/contexts"

export const Footer = () => {
	const { loggedIn } = useSession()
	const year = new Date().getFullYear()
	return (
		<footer>
			<hr size="1" />
			<div className="flex flex-row justify-center py-4">
				<img src="/assets/img/cwe-logo-large.jpg" width="256" />
			</div>
			<div className="bg-black text-gray-400">
				<div className="container mx-auto px-4 pb-4">
					<div className="text-center py-4">
						<p className="text-lg leading-6 pb-4">
							A better way to buy California, Oregon, and Washington wines.
						</p>
						<div className="space-x-4">
							<div className="inline-block">
								<a href="weixin://dl/chat?wxid_jsoahtjr5x3e12">
									<Icon name="WeChat" size="lg" />
								</a>
							</div>
							<div className="inline-block">
								<a href="https://www.instagram.com/collabwinex">
									<Icon name="Instagram" size="lg" />
								</a>
							</div>
							<div className="inline-block">
								<a href="https://twitter.com/collabwinex">
									<Icon name="Twitter" size="lg" />
								</a>
							</div>
						</div>
					</div>

					<div className="text-center w-full border-t border-gray-800 pt-6 pb-2">
						<div>
							<div>
								<div className="text-xl font-extrabold leading-4 mb-1">Subscribe</div>
								<p className="text-grey-darker leading-normal mb-2">
									Sign-up for exclusive offers, wine news, and more.
								</p>
							</div>
							<div>
								<form
									action="https://wine.us7.list-manage.com/subscribe/post?u=2095b7728bc28d6c28592d4b5&amp;id=e8d8ed2f5b"
									method="post"
									id="mc-embedded-subscribe-form"
									name="mc-embedded-subscribe-form"
									className="validate"
									target="_blank"
									noValidate
								>
									<input
										type="email"
										defaultValue=""
										name="EMAIL"
										className="pt-2 pb-1.5 px-4 rounded-l-full text-sm h-auto text-black outline-none placeholder-gray-500"
										placeholder="Your email address"
									/>
									<div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
										<input
											type="text"
											name="b_2095b7728bc28d6c28592d4b5_e8d8ed2f5b"
											tabIndex="-1"
											defaultValue=""
										/>
									</div>
									<button
										type="submit"
										className="bg-red-700 text-white rounded-r-full h-auto text-sm pt-2 pb-1.5 px-4"
									>
										Subscribe
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col sm:flex-row sm:justify-between bg-white text-gray-700 p-4 text-center text-sm">
					<div>
						&copy;<span> </span>
						<span>{`${year} Collaboration Wine Exports LLC. All rights reserved.`}</span>
					</div>
					<div className="mt-2 sm:mt-0">
						<Link className="mx-2" to="/privacy-policy">
							Privacy Policy
						</Link>
						<Link className="mx-2" to="/help">
							Help
						</Link>
						{loggedIn() ? (
							<Link className="mx-2" to="/logout">
								Logout
							</Link>
						) : (
							<Link className="mx-2" to="/login">
								Login
							</Link>
						)}
					</div>
				</div>
			</div>
		</footer>
	)
}
