import React from "react"
import _ from "lodash"

export const Paragraphs = (props) => {
	const { text = "" } = props

	const lines = _.chain(text.toString().split("\n"))
		.filter((line) => !_.isEmpty(line))
		.value()

	return (
		<div className="space-y-2 leading-relaxed">
			{_.map(lines, (line, i) => (
				<p key={i}>{line}</p>
			))}
		</div>
	)
}
