import React from "react"
import ReactDOM from "react-dom"

import "@assets/css/styles.css"
import "@assets/css/global.scss"
import "@assets/css/react-slider.scss"

import { App } from "./app"

ReactDOM.render(<App />, document.getElementById("root"))

/////////////
//////////////////////
////////////////////////////
 
//////////
